{
  "404": {
    "header": "Errore 404 - Pagina non trovata",
    "text": "Il contenuto che state cercando non è stato trovato"
  },
  "footer": {
    "text": "Algo Wallet"
  },
  "login": {
    "new_wallet": "Nuovo wallet",
    "new_wallet_name": "Nome del wallet",
    "new_wallet_pass": "Password del wallet",
    "new_wallet_button_create": "Crea un wallet",
    "new_wallet_button_import": "Importa un wallet",
    "new_wallet_help1": "Il tuo wallet sarà salvato localmente nel tuo browser. La password del wallet è necessaria per accedervi e per firmare le transazioni.",
    "new_wallet_help2": "Si raccomanda di usare una password di almeno 12 caratteri, usando lettere minuscole, maiuscole, numeri e caratteri speciali",
    "open_wallet": "Apri il wallet",
    "select_wallet": "Seleziona il wallet",
    "wallet_password": "Password del wallet",
    "strength": "Robustezza",
    "new_wallet_button_open": "Apri il wallet",
    "basic_usage": "Basic usage",
    "tether_usage": "How to use Tether  (USDt)",
    "source_code": "AWallet source code"
  },
  "global": {
    "go_back": "Indietro",
    "go_home": "Torna alla Home",
    "copy_address": "Copia indirizzo",
    "copied_to_clipboard": "Copiato negli appunti",
    "stop_camera": "Stop camera",
    "cancel": "Cancel",
    "yes": "Yes",
    "no": "No",
    "loading": "Loading.."
  },
  "navbar": {
    "logo": "A Wallet",
    "toggle_nav": "Cambia navigazione",
    "list_accounts": "Mostra i miei account",
    "new_account": "Nuovo account",
    "settings": "Impostazioni",
    "asset": "Asset",
    "asset_create": "Creat NTF",
    "faq": "FAQ",
    "privacy_policy": "Privacy policy",
    "recurring": "Recurring investment",
    "logout": "Logout",
    "vote": "Voting"
  },
  "votemenu": {
    "overview": "Overview",
    "my_questions": "My questions",
    "ask_question": "Ask question",
    "delegation": "Delegation",
    "answers": "Answers",
    "trusted_list": "Trusted list",
    "governance": "Governance",
    "ams01": "Knowledge based voting - AMS-0001"
  },
  "govtoolsmenu": {
    "gen": "Generate accounts",
    "distribute": "Distribute algo 1->N",
    "optin": "Opt in for the governance",
    "pay": "Mass pay N->1",
    "merge": "Merge accounts N->1"
  },
  "voteask": {
    "title": "Ask question through blockchain",
    "question_title": "Question title",
    "title_placeholder": "This will show up in the table list of questions",
    "question_text": "Text of the question",
    "url": "URL address with more question details (start with https://)",
    "url_placeholder": "Add here url address where people can find more information about the question",
    "max_round": "Number of blocks since now until the question will be closed",
    "calculated_block": "Max block",
    "calculated_time": "Estimated time",
    "code": "Code",
    "response_text": "Text of the response option",
    "remove_response": "Remove option",
    "responses_help": "People will answer your question by voting for specific responses. Please provide codes and texts of 2-N responses.",
    "add_response": "Add new ressponse",
    "category": "Question category",
    "category_placeholder": "Knowledge based category vote delegation in effect",
    "submit_question": "Ask question using account {accountName}"
  },
  "votedelegate": {
    "title": "Voting power delegation",
    "intro1": "You can delegate your voting power to someone you trust. It may be your friend or some public person.",
    "intro2": "If you have good friend in finance, you can delegate him voting power in finance category. If he does not vote for questions directly, he might delegate your voting power and his voting power according his wisdom to wise person he know. When the wise person votes, he uses your voting power and he represents your opinions. You can always vote directly in all matters even if you have the delegation. If you vote directly, your voting power stays only at your discrete decision.",
    "intro3": "You can split your voting power to several friends. You set the weight of your voting power distribution in points. If you set 50 points for first friend and 100 points for second friend, first friend will have 50/150 = 33% of your voting power. Second friend will have the rest 100/150 = 67% of your voting power.",
    "intro4": "The category any is the fallback category. If category delegation for specific question is not defined, the any category will be used if defined.",
    "category_any": "Any category",
    "add_account": "Add account",
    "wallet_address": "Wallet address",
    "delegate": "Add account to delegation",
    "category": "Delegate specific category",
    "category_placeholder": "You can specify your delegation in knowledge based category.. eg IT, finance, governance",
    "add_category": "Add specific category",
    "submit_help": "By clicking the button below, you will store your delegation preferences to the blockchain.",
    "submit_text": "Store delegation to the blockchain for {accountName}"
  },
  "voteoverview": {
    "title": "Overview"
  },
  "votequestions": {
    "title": "Questions"
  },
  "votequestionlist": {
    "question_title": "Question title",
    "no_questions": "Be the first one to ask a question",
    "question_text": "Question text",
    "round": "Placed at round",
    "maxround": "Open until round",
    "time": "Time",
    "category": "Category",
    "sender": "Questioner",
    "list": "List all questions",
    "id": "Question id",
    "current_round": "Current round",
    "round_time": "Time of the round",
    "url": "URL",
    "options": "Options",
    "voting_closed": "Voting has been closed",
    "latest_response": "Latest vote for the account {accountName} is selected",
    "vote_button": "Vote with account {accountName}",
    "sum_trusted": "Number of accounted votes",
    "sum_coins": "Sum of accounted coins",
    "trusted_list_results": "Trusted list result",
    "hypercapitalism_results": "1 coin 1 vote results",
    "vote_help": "If you want to vote, select your preferences please. You can assign your preferences for each answer with rating from zero to 100 points. If you want to select only one answer, please give it 100 points, and other answers to zero points. If you do not know, you do not have to vote, or you can assign all answers the equal number of points. If you vote for one answer in 100 points, and other answer 20 points, your voting power for first answer will be 100/120 = 83% and voting power distribution for second answer will be 17%. If you assign all options 100 points, your voting power distribution will be the same as if you assign all options 1 point.",
    "check_results": "Check results"
  },
  "votetl": {
    "title": "Trusted list management",
    "help1": "Questioner can manage list of trusted accounts on this page. Each person may have only one trusted account. The management of this must be provided by the questioner. After the voting will be finished, there are two calculation of votes - one person one vote, and one coin one vote. The voting system does not interpret what the result of voting means. The voting system just calculates the results of votings according to the voting system rules, eg the delegation of votes, proportion of votes, validity of votes and more.",
    "add": "Add accounts to trusted list - one account per line",
    "remove": "Remove accounts from trusted list - one account per line",
    "submit_text": "Store trusted list to blockchain for {accountName}",
    "round": "Added in the round n.",
    "account": "Trusted account",
    "no_tl": "There are no trusted accounts listed for the account {accountName}."
  },
  "voteanswerslist": {
    "no_answers": "There is no response yet to the question",
    "round": "Response round",
    "time": "Response time",
    "sender": "Responder",
    "response": "Response json"
  },
  "merchant": {
    "make_payment": "Payment gateway",
    "pay": "Pay",
    "to_address": "to the algorand address",
    "please": "please",
    "network": "Network",
    "matching_symbol": "Matching symbol",
    "network_fee": "Network fee",
    "pay_qr": "Pay using QR code",
    "pay_nativewallet": "Pay using algorand wallet",
    "pay_webwallet": "Pay using web wallet",
    "cancel_payment": "Cancel payment",
    "payment_received": "Payment received",
    "go_back_to_merchant": "Go back to merchant",
    "waiting_for_payment": "Waiting for the payment"
  },
  "gateway": {
    "title": "Free opensource payment gateway without middleman",
    "perex": "Start accepting algo, USDC, USDT or other assets on your website. By accepting cryptocurrency without financial middleman you will increase your sales on your website and increase your profit.",
    "how": "How it works?",
    "create_order": "Create unique matching symbol, eg. order id",
    "redirect_to_gateway": "Redirect user to the gateway",
    "customer_pays": "Customer pays directly to your algorand account",
    "customer_redirected": "Customer will be redirected to your website back after successful payment",
    "use_your_money": "Use your money. You can echange USDT and take USD from the exchange, or you can pay with USDT for other goods or services",
    "security": "Is it secure?",
    "you_make_details": "You make the payment details. You setup the successful and cancel URL address. We notify you when the payment is done. You have to check in your backend where you accept the payment that it has been received and you notify the customer.",
    "opensource": "This project is open source. You can clone, modify it, or run in your private environment for commercial purposes",
    "just_website": "The payment gateway is just HTML website with javascript. No other backend is used other than official algorand nodes.",
    "no_restrictions": "You do not have AWallet account in order to use the gateway.",
    "allowed_parameters": "Allowed parameters",
    "parameters_perex": "You can set any of these parameters. Only required field is the address where you want to receive your payment and transaction amount. The payment address consits of 2 parts. Payment info, and gateway settings. Both fields are base64 encoded separated by /. Please make sure that you url encode them properly. Payment info is the basic algorand protocol address used for example in the QR codes.",
    "payment_info_params": "Allowed parameters",
    "amount": "use the asset base unit. Eg. 1 ALGO = 1000000",
    "fee": "the amount in microalgos. Eg. 0,001 ALGO = 1000",
    "network": "Network for debug purposes",
    "settings_params": "Allowed settings",
    "success": "URL address where to redirect client after successful payment",
    "cancel": "URL address where to redirect client after he clicks cancel payment",
    "example": "Example",
    "turn_on_build_tool": "Turn on the build tool",
    "turn_off_build_tool": "Turn off the build tool",
    "tether": "Tether mainnet",
    "algorand": "Algorand",
    "asset": "You can specify asset id for the transaction",
    "error_transaction": "Please fill in at least account, amount and asset",
    "error_url": "URL address must start with https:// protocol",
    "button": "Generated button",
    "payment_info": "Payment info",
    "callback_configuration": "Callback configuration",
    "link": "Generated link",
    "html_example": "HTML link example"
  },
  "receive": {
    "title": "Ricevi pagamento",
    "address": "Ricevi pagamento su un indirizzo",
    "amount": "Importo",
    "note": "Note",
    "decimals": "Informazioni sulla posizione dei decimali per il QR Code",
    "label": "Etichetta suggerita per l'indirizzo ricevente",
    "noteeditable": "Le note sono modificabili dall'utente"
  },
  "acc_overview": {
    "title": "Anteprima Account",
    "pay": "Nuovo pagamento",
    "receive_payment": "Ricevi pagamento",
    "name": "Nome",
    "address": "Indirizzo",
    "amount": "Importo",
    "amount_without_pending": "Importo senza le ricompense in attesa",
    "rewards": "Ricompense",
    "pending_rewards": "Ricompense in attesa",
    "reward_base": "Ricompensa base",
    "round": "Round",
    "apps_local_state": "Apps local state",
    "apps_total_schema": "Apps total schema",
    "assets": "Assets",
    "created_apps": "Applicazioni create",
    "status": "Stato",
    "multisignature_threshold": "Soglia per la firma multipla",
    "multisignature_addresses": "Indirizzi per firma multipla",
    "refresh": "Aggiorna",
    "transactions": "Transazioni",
    "no_transactions": "Nessuna transazione trovata",
    "type": "Tipo",
    "time": "Data",
    "tr_amount": "Importo",
    "sender": "Mittente",
    "receiver": "Destinatario",
    "receiver_rewards": "Ricompensa destinatario",
    "confirmed_round": "Round confermato",
    "fee": "Commissione",
    "delete": "Delete account",
    "delete_header": "Delete account",
    "delete_confirm": "Are you sure you want to delete this account from your wallet?",
    "delete_confirm_button": "Delete this account",
    "deleted": "Account has been deleted",
    "asset_optin": "Opt in to asset",
    "ask_question": "Ask question"
  },
  "optin": {
    "title": "Accept transfers of asset in account",
    "searchById": "I know the asset ID",
    "assetId": "Asset ID",
    "assetName": "Asset Name",
    "searchButton": "Find asset",
    "assetInfo": "Information about the asset",
    "total": "Total",
    "unit_name": "Unit name",
    "decimals": "Decimals",
    "url": "URL",
    "metadata_hash": "Metadata hash",
    "default_frozen": "Default frozen",
    "creator": "Creator address",
    "manager": "Manager address",
    "clawback": "Clawback address",
    "freeze": "Freeze address",
    "reserve": "Reserve address",
    "action": "Action",
    "optin_button": "Opt in",
    "dialog_header": "Opt in to asset",
    "optin_confirm": "Are you sure you want to pay 0.001 algo to opt in to this asset? After you opt in, you will be able to receive this asset to your account.",
    "optin_confirm_button": "I want to opt in",
    "asset_not_found": "Asset not found",
    "asset_opt_in_success": "Successfully opt in for the asset"
  },
  "accounts": {
    "title": "Anteprima Account",
    "no_accounts": "Nessun account trovato",
    "account_name": "Nome account",
    "amount": "Importo",
    "address": "Indirizzo",
    "pay": "Paga"
  },
  "newacc": {
    "title": "Nuovo account",
    "create_basic": "Crea un account di base",
    "import_account": "Importa Account",
    "create_multisign_account": "Crea account multifirma",
    "watch_account": "Guarda account",
    "write_mnemonic": "Trascrivi le 25 parole",
    "name": "Nome interno account",
    "create_account": "Crea account",
    "address": "Indirizzi",
    "multisig_help": "Un account multifirma può elaborare le transazioni solo se gli N account indicati alla creazione dell'account firmano la transazione.",
    "select_account_from_list": "Seleziona account esistenti nel tuo wallet",
    "add_other_accounts": "Aggiungi gli account degli amici - un account per riga",
    "trashold_help": "Seleziona quanti account sono necessari per firmare la transazione",
    "create_account_help": "Per creare l'account, assicurati di avere salvato la frase mnemonica. È sicuro mostrare la frase adesso?",
    "show_mnemonic": "Mostra la frase mnemonica",
    "position_question": "Qual è la parola alla posizione n.",
    "mnemonic_help": "Scrivi la frase mnemonica nell'ordine mostrato, copiala o fai uno screenshot e salvala in un luogo sicuro e protetto. Non mostrare la frase a nessuno. Il passo successivo sarà quello di confermare che hai salvato la frase correttamente e dopo aver confermato la parola corretta di una posizione specifica salveremo quel conto in un archivio criptato nel tuo browser. Puoi usare questa frase anche senza salvarla, ad esempio in altri portafogli. Assicurati di non perderla mai, nemmeno fra 10 anni",
    "start_challenge": "Inizia la verifica",
    "create_new": "Crea nuova",
    "hide_mnemonic": "Nascondi menemonico",
    "drop_phrase": "Scarta frase",
    "scan": "Scan from QR",
    "create_vanity": "Create vanity account",
    "vanity_start": "Address will start with",
    "vanity_mid": "Address will contain",
    "vanity_end": "Address will end with",
    "vanity_count": "Number of addresses processed",
    "vanity_button_start": "Find the address",
    "vanity_button_stop": "Stop searching",
    "vanity_workers": "Number of threads to use",
    "vanity_use": "Use this account",
    "auto_lock_off": "Auto lock feature is turned off"
  },
  "import": {
    "title": "Importa wallet",
    "wallet_name": "Nome del Wallet",
    "wallet_file": "File del Wallet",
    "import_wallet_button": "Importa wallet",
    "help": "Puoi importare un wallet precedentemente esportato. La password del wallet rimane all'interno del file esportato, quindi puoi accedervi dopo l'importazione con la stessa password di prima. Puoi cambiare la password dopo il login dalla pagina delle impostazioni."
  },
  "acc_type": {
    "basic_account": "Account di base",
    "multisig_account": "Account multifirma",
    "public_account": "Account pubblico"
  },
  "pay": {
    "title": "Effettua pagamento - da",
    "toggle_camera": "Attiva fotocamera",
    "note_is_b64": "Le note sono codificate in Base64. Se abilitato, puoi inserire direttamente Base64 nel campo della nota e inviarlo come dato grezzi alla rete.",
    "selected_account": "Seleziona account",
    "multisig_account": "Account multifirma",
    "create_proposal": "Crea proposta",
    "sign_proposal": "Firma e invia la proposta",
    "signature_from_friend": "Per favore inserisci qui la firma dell'amico",
    "load_multisig_data": "Carica dati multifirma",
    "pay_to_wallet": "Paga ad un account del wallet",
    "pay_to_other": "Paga ad un altro account",
    "store_other_help": "È preferibile memorizzare il conto a cui si inviano soldi nella rubrica",
    "amount": "Importo",
    "fee": "Commissione",
    "note": "Note",
    "review_payment": "Verifica pagamento",
    "review_payment_help": "Per favore verifica il pagamento",
    "from_account": "Dall'account",
    "pay_to": "Paga all'account",
    "total": "Totale",
    "transaction_details": "Dettagli transazione",
    "type": "Tipo",
    "name": "Nome",
    "first_round": "Primo Round",
    "last_round": "Ultimo Round",
    "genesis": "Genesis ID",
    "tag": "Etichetta",
    "to_account": "A",
    "signatures": "Firme",
    "signed": "Firmato",
    "not_signed": "Non firmato",
    "sign_with": "Firma con",
    "sign": "Firma",
    "send_to_other_signators": "Invia questi dati agli altri firmatari",
    "send_to_network": "Invia alla rete",
    "state_sending": "Sto inviando il pagamento nella rete",
    "state_sent": "Pagamento inviato nella rete. Tx: ",
    "state_waiting_confirm": "In attesa di conferma dalla rete.",
    "state_confirmed": "Conferma ricevuta. Il tuo pagamento è nella blockchain",
    "transaction": "Transazione",
    "error": "Errore",
    "asset": "Asset",
    "state_error_not_sent": "Probabilmente il pagamento non ha raggiunto la rete. Sei offline? Per favore controlla il tuo account",
    "set_max": "Set maxium"
  },
  "settings": {
    "title": "Impostazioni",
    "server": "Server",
    "environment": "Ambiente",
    "mainnet": "Mainnet",
    "testnet": "Testnet",
    "devnet": "Reach Devnet",
    "sandbox": "Sandbox",
    "host": "host",
    "token": "token",
    "language": "Lingua",
    "pass": "Passord del wallet",
    "oldpass": "Vecchia password",
    "newpass": "Nuova password",
    "repeatpass": "Nuova password (ripeti)",
    "backup": "Backup del wallet",
    "backup_help": "Puoi fare un backup del wallet e importarlo in un altro computer.",
    "create_backup": "Crea backup",
    "download": "Download",
    "delete": "Cancella il wallet",
    "strength": "Robustezza",
    "updated_password": "La password è stata aggiornata",
    "custom": "Custom server settings",
    "protocol_title": "Register protocol handler",
    "protocol_button": "Register web+algorand:// handler",
    "protocol_change_success": "Successfully updated the protocol handler"
  },
  "transaction": {
    "title": "Dettagli transazione",
    "tr_id": "ID transazione",
    "tr_type": "Tipo transazione",
    "note": "Note",
    "note_raw": "Dati grezzi",
    "note_decoded": "Dati decodificati",
    "tr_close_rewards": "Chiudi ricompense",
    "closing_amount": "Chiudi importo",
    "confirmed_round": "Round confermato",
    "fee": "Commissioni",
    "first_valid": "Primo valido",
    "genesis_id": "ID Genesis",
    "genesis_hash": "Hash Genesis",
    "intra_round": "Offset intra round",
    "last_valid": "Ultimo valido",
    "payment_tr": "Transazione di pagamento",
    "amount": "Importo",
    "close_amount": "Chiudi importo",
    "receiver": "Destinatario",
    "receiver_rewards": "Ricompensa destinatario",
    "round_time": "Tempo del Round",
    "sender": "Mittente",
    "sender_rewards": "Ricompensa mittente",
    "signature": "Firma",
    "created_asset": "Asset creato",
    "asset_name": "Nome asset",
    "asset_creator": "Autore Asset",
    "asset_manager": "Manager Asset",
    "asset_reserve": "Riserva Asset",
    "asset_freeze": "Congela Asset",
    "asset_clawback": "Revoca Asset",
    "asset_unitName": "Nome Asset unit",
    "asset_total": "Totale Asset",
    "asset_decimals": "Decimali Asset",
    "asset_defaultfrozen": "Asset predefinito congelato",
    "asset_url": "Asset url",
    "asset_metadata": "Asset metadata",
    "time": "Data",
    "group": "Group ID"
  },
  "privacy": {
    "title": "Privacy policy",
    "q1": "Quali informazioni private raccogliete su di me ?",
    "a1": "Nessuna. A parte l'indirizzo IP e il browser che viene memorizzato nei log di ogni servizio internet. Non raccogliamo alcun tipo di informazione privata come il tuo nome, email o indirizzo.",
    "q2": "Cosa memorizzate nei cookie?",
    "a2": "Nulla. Non usiamo cookie. Usiamo un'archiviazione locale persistente chiamata indexdb per salvare il tuo wallet.",
    "q3": "Come raccogliete le informazioni analitiche?",
    "a3": "Non lo facciamo. Non usiamo nessun tipo di servizio di analisi, ad esempio Google Analytics, in modo che possiate sentirvi più sicuri. Il web è puramente html con javascript, e conosciamo il tuo indirizzo IP solo quando fai richieste al server. A seconda delle tue impostazioni e del tuo algod server o indexer, il server selezionato potrebbe anche conoscere il tuo indirizzo IP.",
    "q4": "L'uso di questo servizio influisce sulle pubblicità che vedo?",
    "a4": "No. I server commerciali non hanno alcuna informazione da parte nostra riguardo all'utilizzo. L'unico modo in cui per esempio Google può sapere che stai usando questo servizio è che tu acceda a questo sito dalla ricerca di Google.",
    "q5": "Chi offre questo servizio?",
    "a5": "Scholtz & Company, jsa ha creato questo servizio. ID Azienda: 51 882 272, VAT ID: SK2120828105, con sede a Bratislava, Slovacchia.  Se questo è in esecuzione nel sito ufficiale a-wallet.net, la società è anche fornitore del servizio. La società non è responsabile di alcun servizio, né di eventuali errori nell'applicazione."
  },
  "assetcreate": {
    "title": "Nuovo NTF / ASA",
    "show_advanced": "Mostra form avanzato",
    "assetName": "Nome asset",
    "creator": "Indirizzo autore",
    "hide_advanced": "Nascondi il form avanzato",
    "creator_not_found": "Crea o importa un indirizzo prima",
    "default_fronzen": "Congelato : Gli account degli utenti dovranno essere scongelati prima di effettuare transazioni",
    "decimals": "Decimali: numero intero di decimali per il calcolo dell'unità dell'asset",
    "totalIssuance": "Numero totale di circolante per questo asset",
    "unitName": "Utilizzato per visualizzare le unità di asset all'utente",
    "assetURL": "Stringa opzionale che punta a un URL relativo all'asset",
    "assetMetadataHash": "Hash opzionale relativo all'asset. Lunghezza 32 caratteri.",
    "manager": "Il manager può modificare il manager, la riserva, l'account di congelamento e di revoca per l'asset.",
    "reserve": "Indirizzo di riserva: L'indirizzo specificato è considerato la riserva dell'asset (non ha privilegi speciali, è solo informativo)",
    "freeze": "Indirizzo di congelamento: L'indirizzo specificato può congelare o scongelare gli asset dell'utente",
    "clawback": "Indirizzo di revoca: L'indirizzo specificato può revocare gli asset dell'utente e inviarli ad altri indirizzi",
    "note": "Nota impostata nella transazione di creazione",
    "create_button": "Crea NFT / ASA"
  },
  "faq": {
    "title": "Domande frequenti",
    "q1": "Riguardo al wallet",
    "a1": "L'applicazione Algo wallet è stata inizialmente creata da Scholtz & Company, jsa. Open source, solo html, corporate friendly, e sicurezza sono i principali vantaggi del suo utilizzo.",
    "q2": "Come sono protetti i dati del mio wallet?",
    "a2": "I tuoi dati sono solo nel browser. Sono memorizzati in un database persistente. Ogni wallet è criptato usando aes256 con la tua password. Se scegli una password abbastanza forte, il tuo wallet non sarà mai forzato. La password non lascia il tuo computer e risiede solo nella cache del browser. Dopo 5 minuti dal login la cache viene distrutta.",
    "q3": "La password del mio wallet va su internet?",
    "a3": "Mai",
    "q4": "Come posso cambiare la password del wallet?",
    "a4_1": "Effettua il login e accedi al tuo wallet",
    "a4_2": "Vai alle impostazioni.",
    "a4_3": "Digita la vecchia password, la nuova password e conferma la nuova password.",
    "a4_4": "Invia il form e la tua password verrà cambiata.",
    "q5": "Posso spostare il mio wallet su un altro computer?",
    "a5_1": "Effettua il login e accedi al tuo wallet",
    "a5_2": "Vai alle impostazioni.",
    "a5_3": "Vai alla sezione backup e salva i tuoi dati in un file locale.",
    "a5_4": "Nell'altro computer vai a nuovo wallet, importa wallet, e carica il file esportato nella memoria del nuovo browser.",
    "a5_5": "Accedi al wallet con la stessa password di prima. Puoi cambiare la password nelle impostazioni.",
    "q6": "Posso controllare il tuo codice sorgente?",
    "a6": "Sì. https://github.com/scholtz/wallet/ Questo wallet è stato reso open source ed è libero di essere usato, copiato o modificato anche per scopi commerciali. Se sei in grado di partecipare al progetto, sii così gentile da creare una richiesta di pull con i tuoi contributi.",
    "q7": "Come posso aiutare il progetto?",
    "a7_1": "Una donazione all'indirizzo Algorand P65LXHA5MEDMOJ2ZAITLZWYSU6W25BF2FCXJ5KQRDUB2NT2T7DPAAFYT3U aiuterà il progetto ad andare avanti. Il progetto non è stato finanziato da Algorand inc né dalla fondazione Algorand.",
    "a7_2": "Per tradurre il progetto o aiutare con la traduzione, vai su https://github.com/scholtz/wallet/tree/master/src/locales, copia il file di traduzione nella tua lingua e fai una richiesta di pull.",
    "a7_3": "Se sei un programmatore, per favore fai una revisione del codice, o aiutaci in qualsiasi modo tu pensi che questo progetto possa essere migliorato.",
    "q8": "Come fare un account multifirma?",
    "a8_1": "Per prima cosa create gli account con le chiavi private o create account pubblici. È meglio dare loro dei nomi che vi ricorderanno il loro scopo.",
    "a8_2": "Andate su crea nuovo indirizzo e selezionate l'indirizzo multifirma.",
    "a8_3": "Seleziona tutti i gli account che possono firmare le transazioni e seleziona quante firme sono necessarie come minimo perché la rete accetti il pagamento multifirma.",
    "a8_4": "Dopo aver creato l'account multifirma, puoi accedervi dalla pagina dell'elenco degli account."
  }
}
