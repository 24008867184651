{
  "404": {
    "header": "Error 404 - Pagina niet gevonden",
    "text": "De pagina die je zoekt is niet gevonden"
  },
  "footer": {
    "text": "Algo Portemonnee"
  },
  "login": {
    "new_wallet": "Nieuwe portemonnee",
    "new_wallet_name": "Portemonnee name",
    "new_wallet_pass": "Portemonnee password",
    "new_wallet_button_create": "Maak portemonnee",
    "new_wallet_button_import": "Importeer portemonnee",
    "new_wallet_help1": "Uw portemonnee wordt opgeslagen in uw browser. Portemonnee wachtwoord is vereist om uw portemonnee te openen en uw accounts hierin te zien of om een verrichting te tekenen.",
    "new_wallet_help2": "Wij adviseren een wachtwoord lengte van tenminste 12 characters, met het gebruik van kleine letters, hoofdletters een nummer en een speciaal teken.",
    "open_wallet": "Open portemonnee",
    "select_wallet": "Selecteer portemonnee",
    "wallet_password": "Portemonnee wachtwoord",
    "strength": "Sterkte",
    "new_wallet_button_open": "Open portemonnee",
    "basic_usage": "Basic usage",
    "tether_usage": "How to use Tether  (USDt)",
    "source_code": "AWallet source code"
  },
  "global": {
    "go_back": "Ga terug",
    "go_home": "Naar homepagina",
    "copy_address": "Kopieer adres",
    "copied_to_clipboard": "Gekopieerd naar uw klembord",
    "stop_camera": "Stop camera",
    "cancel": "Cancel",
    "yes": "Yes",
    "no": "No",
    "loading": "Loading.."
  },
  "navbar": {
    "logo": "Een portemonnee",
    "toggle_nav": "Navigatie schakel",
    "list_accounts": "Toon mijn accounts",
    "new_account": "Nieuw account",
    "settings": "Instellingen",
    "asset": "activa",
    "asset_create": "Maak NTF",
    "faq": "Veelgestelde Vragen",
    "privacy_policy": "Privacy beleid",
    "recurring": "Terugkerende investering",
    "logout": "Uitloggen",
    "vote": "Voting"
  },
  "votemenu": {
    "overview": "Overview",
    "my_questions": "My questions",
    "ask_question": "Ask question",
    "delegation": "Delegation",
    "answers": "Answers",
    "trusted_list": "Trusted list",
    "governance": "Governance",
    "ams01": "Knowledge based voting - AMS-0001"
  },
  "govtoolsmenu": {
    "gen": "Generate accounts",
    "distribute": "Distribute algo 1->N",
    "optin": "Opt in for the governance",
    "pay": "Mass pay N->1",
    "merge": "Merge accounts N->1"
  },
  "voteask": {
    "title": "Ask question through blockchain",
    "question_title": "Question title",
    "title_placeholder": "This will show up in the table list of questions",
    "question_text": "Text of the question",
    "url": "URL address with more question details (start with https://)",
    "url_placeholder": "Add here url address where people can find more information about the question",
    "max_round": "Number of blocks since now until the question will be closed",
    "calculated_block": "Max block",
    "calculated_time": "Estimated time",
    "code": "Code",
    "response_text": "Text of the response option",
    "remove_response": "Remove option",
    "responses_help": "People will answer your question by voting for specific responses. Please provide codes and texts of 2-N responses.",
    "add_response": "Add new ressponse",
    "category": "Question category",
    "category_placeholder": "Knowledge based category vote delegation in effect",
    "submit_question": "Ask question using account {accountName}"
  },
  "votedelegate": {
    "title": "Voting power delegation",
    "intro1": "You can delegate your voting power to someone you trust. It may be your friend or some public person.",
    "intro2": "If you have good friend in finance, you can delegate him voting power in finance category. If he does not vote for questions directly, he might delegate your voting power and his voting power according his wisdom to wise person he know. When the wise person votes, he uses your voting power and he represents your opinions. You can always vote directly in all matters even if you have the delegation. If you vote directly, your voting power stays only at your discrete decision.",
    "intro3": "You can split your voting power to several friends. You set the weight of your voting power distribution in points. If you set 50 points for first friend and 100 points for second friend, first friend will have 50/150 = 33% of your voting power. Second friend will have the rest 100/150 = 67% of your voting power.",
    "intro4": "The category any is the fallback category. If category delegation for specific question is not defined, the any category will be used if defined.",
    "category_any": "Any category",
    "add_account": "Add account",
    "wallet_address": "Wallet address",
    "delegate": "Add account to delegation",
    "category": "Delegate specific category",
    "category_placeholder": "You can specify your delegation in knowledge based category.. eg IT, finance, governance",
    "add_category": "Add specific category",
    "submit_help": "By clicking the button below, you will store your delegation preferences to the blockchain.",
    "submit_text": "Store delegation to the blockchain for {accountName}"
  },
  "voteoverview": {
    "title": "Overview"
  },
  "votequestions": {
    "title": "Questions"
  },
  "votequestionlist": {
    "question_title": "Question title",
    "no_questions": "Be the first one to ask a question",
    "question_text": "Question text",
    "round": "Placed at round",
    "maxround": "Open until round",
    "time": "Time",
    "category": "Category",
    "sender": "Questioner",
    "list": "List all questions",
    "id": "Question id",
    "current_round": "Current round",
    "round_time": "Time of the round",
    "url": "URL",
    "options": "Options",
    "voting_closed": "Voting has been closed",
    "latest_response": "Latest vote for the account {accountName} is selected",
    "vote_button": "Vote with account {accountName}",
    "sum_trusted": "Number of accounted votes",
    "sum_coins": "Sum of accounted coins",
    "trusted_list_results": "Trusted list result",
    "hypercapitalism_results": "1 coin 1 vote results",
    "vote_help": "If you want to vote, select your preferences please. You can assign your preferences for each answer with rating from zero to 100 points. If you want to select only one answer, please give it 100 points, and other answers to zero points. If you do not know, you do not have to vote, or you can assign all answers the equal number of points. If you vote for one answer in 100 points, and other answer 20 points, your voting power for first answer will be 100/120 = 83% and voting power distribution for second answer will be 17%. If you assign all options 100 points, your voting power distribution will be the same as if you assign all options 1 point.",
    "check_results": "Check results"
  },
  "votetl": {
    "title": "Trusted list management",
    "help1": "Questioner can manage list of trusted accounts on this page. Each person may have only one trusted account. The management of this must be provided by the questioner. After the voting will be finished, there are two calculation of votes - one person one vote, and one coin one vote. The voting system does not interpret what the result of voting means. The voting system just calculates the results of votings according to the voting system rules, eg the delegation of votes, proportion of votes, validity of votes and more.",
    "add": "Add accounts to trusted list - one account per line",
    "remove": "Remove accounts from trusted list - one account per line",
    "submit_text": "Store trusted list to blockchain for {accountName}",
    "round": "Added in the round n.",
    "account": "Trusted account",
    "no_tl": "There are no trusted accounts listed for the account {accountName}."
  },
  "voteanswerslist": {
    "no_answers": "There is no response yet to the question",
    "round": "Response round",
    "time": "Response time",
    "sender": "Responder",
    "response": "Response json"
  },
  "merchant": {
    "make_payment": "Payment gateway",
    "pay": "Pay",
    "to_address": "to the algorand address",
    "please": "please",
    "network": "Network",
    "matching_symbol": "Matching symbol",
    "network_fee": "Network fee",
    "pay_qr": "Pay using QR code",
    "pay_nativewallet": "Pay using algorand wallet",
    "pay_webwallet": "Pay using web wallet",
    "cancel_payment": "Cancel payment",
    "payment_received": "Payment received",
    "go_back_to_merchant": "Go back to merchant",
    "waiting_for_payment": "Waiting for the payment"
  },
  "gateway": {
    "title": "Free opensource payment gateway without middleman",
    "perex": "Start accepting algo, USDC, USDT or other assets on your website. By accepting cryptocurrency without financial middleman you will increase your sales on your website and increase your profit.",
    "how": "How it works?",
    "create_order": "Create unique matching symbol, eg. order id",
    "redirect_to_gateway": "Redirect user to the gateway",
    "customer_pays": "Customer pays directly to your algorand account",
    "customer_redirected": "Customer will be redirected to your website back after successful payment",
    "use_your_money": "Use your money. You can echange USDT and take USD from the exchange, or you can pay with USDT for other goods or services",
    "security": "Is it secure?",
    "you_make_details": "You make the payment details. You setup the successful and cancel URL address. We notify you when the payment is done. You have to check in your backend where you accept the payment that it has been received and you notify the customer.",
    "opensource": "This project is open source. You can clone, modify it, or run in your private environment for commercial purposes",
    "just_website": "The payment gateway is just HTML website with javascript. No other backend is used other than official algorand nodes.",
    "no_restrictions": "You do not have AWallet account in order to use the gateway.",
    "allowed_parameters": "Allowed parameters",
    "parameters_perex": "You can set any of these parameters. Only required field is the address where you want to receive your payment and transaction amount. The payment address consits of 2 parts. Payment info, and gateway settings. Both fields are base64 encoded separated by /. Please make sure that you url encode them properly. Payment info is the basic algorand protocol address used for example in the QR codes.",
    "payment_info_params": "Allowed parameters",
    "amount": "use the asset base unit. Eg. 1 ALGO = 1000000",
    "fee": "the amount in microalgos. Eg. 0,001 ALGO = 1000",
    "network": "Network for debug purposes",
    "settings_params": "Allowed settings",
    "success": "URL address where to redirect client after successful payment",
    "cancel": "URL address where to redirect client after he clicks cancel payment",
    "example": "Example",
    "turn_on_build_tool": "Turn on the build tool",
    "turn_off_build_tool": "Turn off the build tool",
    "tether": "Tether mainnet",
    "algorand": "Algorand",
    "asset": "You can specify asset id for the transaction",
    "error_transaction": "Please fill in at least account, amount and asset",
    "error_url": "URL address must start with https:// protocol",
    "button": "Generated button",
    "payment_info": "Payment info",
    "callback_configuration": "Callback configuration",
    "link": "Generated link",
    "html_example": "HTML link example"
  },
  "receive": {
    "title": "Ontvang transactie",
    "address": "Ontvang transactie naar adres",
    "amount": "Bedrag",
    "note": "Notitie",
    "decimals": "Informatie over decimalen voor een QR",
    "label": "Gesuggereerd label voor ontvangst adres",
    "noteeditable": "Notitie is bewerkbaar door gebruikber"
  },
  "acc_overview": {
    "title": "Account overzicht",
    "pay": "Maak een betaling",
    "receive_payment": "Ontvang betaling",
    "name": "Naam",
    "address": "Adres",
    "amount": "Bedrag",
    "amount_without_pending": "Bedrag zonder wachtende beloningen",
    "rewards": "Beloningen",
    "pending_rewards": "Wachtende beloningen",
    "reward_base": "Belonings basis",
    "round": "Ronde",
    "apps_local_state": "Applicaties locale staat",
    "apps_total_schema": "Applicaties totale schema",
    "assets": "activaten",
    "created_apps": "Gemaakte applicaties",
    "status": "Status",
    "multisignature_threshold": "Multisignature drempel",
    "multisignature_addresses": "Multisignature addressen",
    "refresh": "Ververs",
    "transactions": "Transacties",
    "no_transactions": "Geen transacties gevonden",
    "type": "Type",
    "time": "Tijd",
    "tr_amount": "Bedrag",
    "sender": "Zender",
    "receiver": "Ontvanger",
    "receiver_rewards": "Ontvanger beloningen",
    "confirmed_round": "Bevestigde ronde",
    "fee": "Kost",
    "delete": "Delete account",
    "delete_header": "Delete account",
    "delete_confirm": "Are you sure you want to delete this account from your wallet?",
    "delete_confirm_button": "Delete this account",
    "deleted": "Account has been deleted",
    "asset_optin": "Opt in to asset",
    "ask_question": "Ask question"
  },
  "optin": {
    "title": "Accept transfers of asset in account",
    "searchById": "I know the asset ID",
    "assetId": "Asset ID",
    "assetName": "Asset Name",
    "searchButton": "Find asset",
    "assetInfo": "Information about the asset",
    "total": "Total",
    "unit_name": "Unit name",
    "decimals": "Decimals",
    "url": "URL",
    "metadata_hash": "Metadata hash",
    "default_frozen": "Default frozen",
    "creator": "Creator address",
    "manager": "Manager address",
    "clawback": "Clawback address",
    "freeze": "Freeze address",
    "reserve": "Reserve address",
    "action": "Action",
    "optin_button": "Opt in",
    "dialog_header": "Opt in to asset",
    "optin_confirm": "Are you sure you want to pay 0.001 algo to opt in to this asset? After you opt in, you will be able to receive this asset to your account.",
    "optin_confirm_button": "I want to opt in",
    "asset_not_found": "Asset not found",
    "asset_opt_in_success": "Successfully opt in for the asset"
  },
  "accounts": {
    "title": "Accounts overzicht",
    "no_accounts": "Geen accounts gevonden",
    "account_name": "Account naam",
    "amount": "Bedrag",
    "address": "Adres",
    "pay": "Betaal"
  },
  "newacc": {
    "title": "Nieuw account",
    "create_basic": "Maak een basis account",
    "import_account": "Importeer account",
    "create_multisign_account": "Maak een multisignature account",
    "watch_account": "Bekijk account",
    "write_mnemonic": "Schrijf uw 25 woorden geheugensteuntje op.",
    "name": "Intern account naam",
    "create_account": "Maak account",
    "address": "Adres",
    "multisig_help": "Een multisignature account kan alleen transacties vormen als N accounts vermeld bij het maken van uw account de transactie tekenen.",
    "select_account_from_list": "Selecteer bestaande accounts in uw portemonnee",
    "add_other_accounts": "Voeg uw vrienden toe - een account per regel",
    "trashold_help": "Selecteer hoeveel accounts moeten tekenen",
    "create_account_help": "Als u een account wilt maken moet u uw 24 woorden geheugensteuntje opschrijven. Is het veilig om hem nu te laten zien?",
    "show_mnemonic": "Toon gegeuhensteun",
    "position_question": "Wat is het woord op de n.e positie",
    "mnemonic_help": "Schrijf uw geheugensteun woorden in getoonde volgorde op, kopieer ze of maak er een foto van. De volgende stap zal zijn om enkele woorden uit deze zin te bevestigen en wij zullen het opslaan in de gecodeerde opslag in uw browser. Het is veilig om deze geheugensteun op te slaan en dit adres te gebruiken zonder het op de account op te slaan. Zorg ervoor dat je de geheugensteun zin nooit verliest.",
    "start_challenge": "Start de uitdaging",
    "create_new": "Maak nieuw",
    "hide_mnemonic": "Verberg geheugensteun",
    "drop_phrase": "Laat zin vallen",
    "scan": "Scan from QR",
    "create_vanity": "Create vanity account",
    "vanity_start": "Address will start with",
    "vanity_mid": "Address will contain",
    "vanity_end": "Address will end with",
    "vanity_count": "Number of addresses processed",
    "vanity_button_start": "Find the address",
    "vanity_button_stop": "Stop searching",
    "vanity_workers": "Number of threads to use",
    "vanity_use": "Use this account",
    "auto_lock_off": "Auto lock feature is turned off"
  },
  "import": {
    "title": "Importeer portemonee",
    "wallet_name": "Portemonnee naam",
    "wallet_file": "Portemonnee bestand",
    "import_wallet_button": "Importeer portemonnee",
    "help": "U kunt eerder geëxporteerde portemonnees importeren. Het wachtwoord van de portemonnee blijft in de geëxporteerde portemonnee, zodat u deze na het importeren kunt openen met hetzelfde wachtwoord als voorheen. U kunt het wachtwoord wijzigen nadat u zich hebt aangemeld via de instellingenpagina."
  },
  "acc_type": {
    "basic_account": "Basis account",
    "multisig_account": "Multisignature accout",
    "public_account": "Publiek account"
  },
  "pay": {
    "title": "Maak een betaling - van",
    "toggle_camera": "Toggle camera",
    "note_is_b64": "Notitie is Base64 gecodeerd. Indien ingeschakeld kunt u base64 invoer in het notitieveld plaatsen en het als onbewerkte gegevens naar het netwerk sturen.",
    "selected_account": "Geselecteerd account",
    "multisig_account": "Multisignature account",
    "create_proposal": "Voorstel maken",
    "sign_proposal": "Teken & verstuur voorstel",
    "signature_from_friend": "Vul hier de handtekening van uw vriend in",
    "load_multisig_data": "Laad de multisignature data",
    "pay_to_wallet": "Verstuur naar portemonnee account",
    "pay_to_other": "Verstuur naar ander account",
    "store_other_help": "Het is beter om de rekening waarnaar u geld stuurt in het adresboek op te slaan",
    "amount": "Bedrag",
    "fee": "Vergoeding",
    "note": "Notitie",
    "review_payment": "Herzie betaling",
    "review_payment_help": "Herzie uw betaling svp.",
    "from_account": "Van account",
    "pay_to": "Verstuur naar account",
    "total": "Totaal",
    "transaction_details": "Transactie details",
    "type": "Type",
    "name": "Naam",
    "first_round": "EersteRonde",
    "last_round": "TweedeRonde",
    "genesis": "GenesisID",
    "tag": "Label",
    "to_account": "Naar",
    "signatures": "Handtekeningen",
    "signed": "Ondertekend",
    "not_signed": "Niet ondertekend",
    "sign_with": "Teken met",
    "sign": "Onderteken",
    "send_to_other_signators": "Stuur deze gegevens naar andere ondertekenaars",
    "send_to_network": "Stuur naar het netwerk",
    "state_sending": "Betaling wordt naar het netwerk verstuurt",
    "state_sent": "Betaling naar het network verstuurd. Tx: ",
    "state_waiting_confirm": "Wachtend voor netwerk confirmaties.",
    "state_confirmed": "Confirmaties zijn ontvangen, uw betaling is in het block!",
    "transaction": "Transactie",
    "error": "Error",
    "asset": "activaten",
    "state_error_not_sent": "De betaling heeft het netwerk waarschijnlijk niet bereikt. Bent u offline? Controleer uw account",
    "set_max": "Set maxium"
  },
  "settings": {
    "title": "Instellingen",
    "server": "Server",
    "environment": "Omgeving",
    "mainnet": "Mainnet",
    "testnet": "Testnet",
    "devnet": "Reach Devnet",
    "sandbox": "Sandbox",
    "host": "host",
    "token": "token",
    "language": "Taal",
    "pass": "Portemonnee wachtwoord",
    "oldpass": "Oud wachtwoord",
    "newpass": "Nieuw wachtwoord",
    "repeatpass": "Nieuw wachtwoord - herhalen",
    "backup": "Backup portemonnee",
    "backup_help": "U kunt een back-up van uw portemonnnee maken en importeren naar een andere computer.",
    "create_backup": "Maak backup",
    "download": "Download",
    "delete": "Verwijder uw portemonnee",
    "strength": "Sterkte",
    "updated_password": "Wachtwoord is geupdate",
    "custom": "Custom server settings",
    "protocol_title": "Register protocol handler",
    "protocol_button": "Register web+algorand:// handler",
    "protocol_change_success": "Successfully updated the protocol handler"
  },
  "transaction": {
    "title": "Transactie details",
    "tr_id": "Tramsactie ID",
    "tr_type": "Transactie type",
    "note": "Notitie",
    "note_raw": "Ruwe data",
    "note_decoded": "Gedecodeerde data",
    "tr_close_rewards": "Sluit beloningen",
    "closing_amount": "Sluitend bedrag",
    "confirmed_round": "Bevestigde ronde",
    "fee": "Vergoeding",
    "first_valid": "Eerste geldig",
    "genesis_id": "Genesis id",
    "genesis_hash": "Genesis hash",
    "intra_round": "Intra-ronde afwijking",
    "last_valid": "Laatste geldig",
    "payment_tr": "Betalings transactie",
    "amount": "Bedrag",
    "close_amount": "Sluitend bedrag",
    "receiver": "Ontvanger",
    "receiver_rewards": "Ontvanger beloning",
    "round_time": "Ronde tijd",
    "sender": "Verzender",
    "sender_rewards": "Verzender beloning",
    "signature": "Ondertekening",
    "created_asset": "Gemaakt activa",
    "asset_name": "activa naam",
    "asset_creator": "activa maker",
    "asset_manager": "activa manager",
    "asset_reserve": "activa reserve",
    "asset_freeze": "activa vriezen",
    "asset_clawback": "Activa terugvordering",
    "asset_unitName": "Activa eenheid naam",
    "asset_total": "Activa totaal",
    "asset_decimals": "Activa decimalen",
    "asset_defaultfrozen": "Activa standaard bevroren",
    "asset_url": "Activa url",
    "asset_metadata": "Activa metadata",
    "time": "Tijd",
    "group": "Group ID"
  },
  "privacy": {
    "title": "Privacy beleid",
    "q1": "Welke privé-informatie over mij verzamel je?",
    "a1": "Geen. Behalve IP-adres en browser, die voor elke internetdienst in logboeken wordt opgeslagen. Wij verzamelen geen enkele vorm van prive-informatie zoals uw naam, email of adres.",
    "q2": "Wat sla je op in cookies?",
    "a2": "Niets. Wij gebruiken geen cookies. We gebruiken persistente lokale opslag genaamd indexdb voor het bewaren van uw portemonnee.",
    "q3": "Hoe verzamel je analytische informatie?",
    "a3": "Dat doen we niet. Wij gebruiken geen enkele vorm van analytics service bv google analytics, zodat u zich veiliger kunt voelen. Het web is puur html met javascript, en wij kennen uw IP adres alleen als u de server opvraagt. Afhankelijk van uw instellingen en uw algod server of indexer, kan de geselecteerde server ook uw IP-adres weten.",
    "q4": "Heeft het gebruik van deze dienst invloed op de advertenties die ik zie?",
    "a4": "Nee. Commerciële servers hebben geen informatie van ons over enig gebruik. De enige manier waarop bijvoorbeeld google kan weten dat u deze dienst gebruikt is dat u deze site bezoekt via google zoekopdracht.",
    "q5": "Wie verleent deze dienst?",
    "a5": "Scholtz & Company, jsa heeft deze dienst gecreëerd. Bedrijfs ID: 51 882 272, BTW ID: SK2120828105, gevestigd in Bratislava, Slowakije.  Als dit draait op de officiële website a-wallet.net, is het bedrijf ook dienstverlener. Het bedrijf is niet aansprakelijk voor de dienst, noch voor fouten in de toepassing."
  },
  "assetcreate": {
    "title": "Nieuw NTF / ASA",
    "show_advanced": "Toon geavanceerd formulier",
    "assetName": "Vriendelijke name",
    "creator": "Maker adres",
    "hide_advanced": "Verberg geavanceerd formulier",
    "creator_not_found": "Maak adres of importeer adres eerst",
    "default_fronzen": "Bevroren : Gebruikersaccounts moeten worden gedeblokkeerd alvorens transacties te verrichten",
    "decimals": "Decimalen: geheel aantal decimalen voor berekening activa-eenheid",
    "totalIssuance": "Totaal aantal van dit goed beschikbaar voor circulatie",
    "unitName": "Gebruikt om activa eenheden te tonen aan de gebruiker",
    "assetURL": "Optionele string die verwijst naar een URL met betrekking tot het activa",
    "assetMetadataHash": "Optionele hash-verbintenis van een soort met betrekking tot het goed. Lengte 32 tekens.",
    "manager": "Beheerder kan beheerder beamen, reserve, bevriezing en terugvorderingsrekening voor het goed wijzigen.",
    "reserve": "Reserve adres: Gespecificeerd adres wordt beschouwd als de activareserve (het heeft geen speciale voorrechten, dit is slechts informatief)",
    "freeze": "Bevries adres: Gespecificeerd adres kan tegoeden van gebruiker bevriezen of deblokkeren",
    "clawback": "Terugvordering-adres: Gespecificeerd adres kan tegoeden van gebruikers intrekken en naar andere adressen sturen",
    "note": "Opmerking ingesteld in de aanmaak transactie",
    "create_button": "Maak NFT / ASA"
  },
  "faq": {
    "title": "Veelgestelde vragen",
    "q1": "Over de portemonnee",
    "a1": "Algo wallet applicatie is in eerste instantie gemaakt door Scholtz & Company, jsa. Open source, html only, coroporate vriendelijk, en veiligheid zijn de belangrijkste voordelen voor het gebruik ervan.",
    "q2": "Hoe is mijn wallet data beveiligd?",
    "a2": "Uw gegevens zijn alleen in de browser. Het wordt opgeslagen in een persistente index db. Elke portemonnee is versleuteld met aes256 met uw wachtwoord. Als u een sterk genoeg wachtwoord maakt, zal uw portemonnee nooit worden gebruteforceerd. Het wachtwoord verlaat uw computer niet en bevindt zich alleen in de cache van de browser. Na 5 minuten na het inloggen wordt de cache vernietigd.",
    "q3": "Gaat het wachtwoord van mijn portemonnee naar het internet?",
    "a3": "Nooit",
    "q4": "Hoe kan ik het wachtwoord van de portefeuille wijzigen?",
    "a4_1": "Log in en krijg toegang tot uw portemonnee",
    "a4_2": "Navigeer naar instellingen.",
    "a4_3": "Typ oud wachtwoord, nieuw wachtwoord, en bevestig nieuw wachtwoord.",
    "a4_4": "Verstuur formulier en uw wachtwoord wordt gewijzigd.",
    "q5": "Kan ik mijn portefeuille naar een andere computer verplaatsen?",
    "a5_1": "Inloggen en toegang tot uw portemonnee",
    "a5_2": "Navigeer naar instellingen.",
    "a5_3": "Ga naar sectie back-up en sla uw gegevens op in het lokale bestand.",
    "a5_4": "Ga in de andere computer naar nieuwe portemonnee, importeer portemonnee, en upload het gedownloade bestand naar de andere browseropslag.",
    "a5_5": "Krijg toegang tot de portemonnee met hetzelfde wachtwoord als voorheen. U kunt het wachtwoord wijzigen in de instellingen.",
    "q6": "Kan ik uw broncode bekijken?",
    "a6": "Ja. https://github.com/scholtz/wallet/ Deze wallet is open source gemaakt en is vrij om te gebruiken, te kopiëren of aan te passen, zelfs voor commerciële doeleinden. Als u in staat bent om deel te nemen aan het project, wees dan zo vriendelijk en maak een pull request aan met uw verbetering.",
    "q7": "Hoe kan ik het project helpen?",
    "a7_1": "Donatie aan algorand adres P65LXHA5MEDMOJ2ZAITLZWYSU6W25BF2FCXJ5KQRDUB2NT2T7DPAAFYT3U zal het project op gang helpen. Het project is niet gefinancierd door algorand inc noch door algorand foundation.",
    "a7_2": "Om het project te vertalen of te helpen met de vertaling, ga naar https://github.com/scholtz/wallet/tree/master/src/locales, kopieer het vertaalbestand naar jouw taal en doe een pull-verzoek.",
    "a7_3": "Als je een programmeur bent, doe dan alsjeblieft een code review, of help ons op welke manier dan ook waarvan je denkt dat dit project beter zou moeten zijn.",
    "q8": "Hoe maak je een rekening met meerdere handtekeningen?",
    "a8_1": "Maak eerst de accounts aan met de private sleutels of maak publieke accounts aan. Het is beter om ze namen te geven die je zult onthouden voor het doel ervan.",
    "a8_2": "Ga naar nieuw adres aanmaken en selecteer adres met meerdere handtekeningen.",
    "a8_3": "Selecteer alle accounts die de transacties kunnen ondertekenen en selecteer hoeveel handtekeningen minimaal zijn voor het netwerk om de multisig-betaling te accepteren.",
    "a8_4": "Nadat u de multisignature-rekening hebt aangemaakt, kunt u deze openen via de rekeningenlijstpagina."
  }
}
