<template>
  <PublicLayout>
    <h1>Payment has been successful</h1>
    <p>Thank you for your payment</p>
  </PublicLayout>
</template>

<script>
import PublicLayout from "../layouts/Public.vue";
export default {
  components: {
    PublicLayout,
  },
};
</script>