<template>
  <PublicLayout>
    <h1>{{ $t("assetrevoke.title") }}</h1>
  </PublicLayout>
</template>

<script>
import PublicLayout from "../../layouts/Public.vue";
export default {
  components: {
    PublicLayout,
  },
};
</script>