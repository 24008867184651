{
  "404": {
    "header": "Chyba 404 - Stránka nebola nájdená",
    "text": "Stránka ktorú hľadáte nie je bohužiaľ k dispozícii. Skúste prejsť na hlavnú stránku."
  },
  "footer": {
    "text": "Algo Peňaženka"
  },
  "login": {
    "new_wallet": "Nová peňaženka",
    "new_wallet_name": "Názov peňaženky",
    "new_wallet_pass": "Heslo peňaženky",
    "new_wallet_button_create": "Vytvoriť peňaženku",
    "new_wallet_button_import": "Importovať peňaženku",
    "new_wallet_help1": "Vaša peňaženka bude uložená v prehliadači. Heslo je potrebné k odomknutiu peňaženky a možnosti odosielania vašich aktív.",
    "new_wallet_help2": "Odporúčame použiť veľmi silné heslo. Aspoň 12 znakov s použitím znakov zo skupín malé písmená, veľké písmená, číslice a špeciálne znaky",
    "open_wallet": "Otvoriť peňaženku",
    "select_wallet": "Vybrať peňaženku",
    "wallet_password": "Heslo do peňaženky",
    "strength": "Sila hesla",
    "new_wallet_button_open": "Otvoriť peňaženku",
    "basic_usage":"Základné použitie",
    "tether_usage":"Ako používať Tether (USDt)",
    "source_code":"Zdrojový kód AWallet"
  },
  "global": {
    "go_back": "Naspäť",
    "go_home": "Domov",
    "copy_address": "Skopírovať adresu",
    "copied_to_clipboard": "Skopírované",
    "stop_camera":"Vypnúť kameru",
    "cancel":"Zrušiť",
    "yes":"Áno",
    "no":"Nie",
    "loading":"Šťahujem údaje.."
  },
  "navbar": {
    "logo": "A Wallet",
    "toggle_nav": "Prepnúť navigáciu",
    "list_accounts": "Zoznam mojich účtov",
    "new_account": "Nový účet",
    "settings": "Nastavenia",
    "asset": "Aktíva",
    "asset_create": "Vytvoriť NTF",
    "faq": "FAQ",
    "privacy_policy": "Ochrana osob. údajov",
    "recurring": "Opakujúca sa investícia",
    "logout": "Odhlásenie",
    "vote":"Voting"
  },
  "votemenu": {
    "overview": "Overview",
    "my_questions": "My questions",
    "ask_question": "Ask question",
    "delegation": "Delegation",
    "answers": "Answers",
    "trusted_list": "Trusted list",
    "governance":"Governance",
    "ams01":"Knowledge based voting - AMS-0001"
  },
  "govtoolsmenu": {
    "gen": "Generate accounts",
    "distribute": "Distribute algo 1->N",
    "optin": "Opt in for the governance",
    "pay": "Mass pay N->1",
    "merge": "Merge accounts N->1"
  },
  "voteask":{
    "title":"Ask question through blockchain",
    "question_title":"Question title",
    "title_placeholder":"This will show up in the table list of questions",
    "question_text":"Text of the question",
    "url":"URL address with more question details (start with https://)",
    "url_placeholder":"Add here url address where people can find more information about the question",
    "max_round":"Number of blocks since now until the question will be closed",
    "calculated_block":"Max block",
    "calculated_time":"Estimated time",
    "code":"Code",
    "response_text":"Text of the response option",
    "remove_response":"Remove option"   ,
    "responses_help":"People will answer your question by voting for specific responses. Please provide codes and texts of 2-N responses.",
    "add_response":"Add new ressponse", 
    "category":"Question category",
    "category_placeholder":"Knowledge based category vote delegation in effect",
    "submit_question":"Ask question using account {accountName}"    
  },
  "votedelegate":{
    "title":"Voting power delegation",
    "intro1":"You can delegate your voting power to someone you trust. It may be your friend or some public person.",
    "intro2":"If you have good friend in finance, you can delegate him voting power in finance category. If he does not vote for questions directly, he might delegate your voting power and his voting power according his wisdom to wise person he know. When the wise person votes, he uses your voting power and he represents your opinions. You can always vote directly in all matters even if you have the delegation. If you vote directly, your voting power stays only at your discrete decision.",
    "intro3":"You can split your voting power to several friends. You set the weight of your voting power distribution in points. If you set 50 points for first friend and 100 points for second friend, first friend will have 50/150 = 33% of your voting power. Second friend will have the rest 100/150 = 67% of your voting power.",
    "intro4":"The category any is the fallback category. If category delegation for specific question is not defined, the any category will be used if defined.",
    "category_any":"Any category",
    "add_account":"Add account",
    "wallet_address":"Wallet address",
    "delegate":"Add account to delegation",
    "category":"Delegate specific category",
    "category_placeholder":"You can specify your delegation in knowledge based category.. eg IT, finance, governance",
    "add_category":"Add specific category",
    "submit_help":"By clicking the button below, you will store your delegation preferences to the blockchain.",
    "submit_text":"Store delegation to the blockchain for {accountName}"
  },
  "voteoverview":{
    "title":"Overview"
  },
  "votequestions":{
    "title":"Questions"
  },
  "votequestionlist":{
    "question_title":"Question title",
    "no_questions":"Be the first one to ask a question",
    "question_text":"Question text",
    "round":"Placed at round",
    "maxround":"Open until round",
    "time":"Time",
    "category":"Category",
    "sender":"Questioner",
    "list":"List all questions",
    "id":"Question id",
    "current_round":"Current round",
    "round_time":"Time of the round",
    "url":"URL",
    "options":"Options",
    "voting_closed":"Voting has been closed",
    "latest_response":"Latest vote for the account {accountName} is selected",
    "vote_button":"Vote with account {accountName}",
    "sum_trusted":"Number of accounted votes",
    "sum_coins":"Sum of accounted coins",
    "trusted_list_results":"Trusted list result",
    "hypercapitalism_results":"1 coin 1 vote results",
    "vote_help":"If you want to vote, select your preferences please. You can assign your preferences for each answer with rating from zero to 100 points. If you want to select only one answer, please give it 100 points, and other answers to zero points. If you do not know, you do not have to vote, or you can assign all answers the equal number of points. If you vote for one answer in 100 points, and other answer 20 points, your voting power for first answer will be 100/120 = 83% and voting power distribution for second answer will be 17%. If you assign all options 100 points, your voting power distribution will be the same as if you assign all options 1 point.",
    "check_results":"Check results"
  },
  "votetl":{
    "title":"Trusted list management",    
    "help1":"Questioner can manage list of trusted accounts on this page. Each person may have only one trusted account. The management of this must be provided by the questioner. After the voting will be finished, there are two calculation of votes - one person one vote, and one coin one vote. The voting system does not interpret what the result of voting means. The voting system just calculates the results of votings according to the voting system rules, eg the delegation of votes, proportion of votes, validity of votes and more.",
    "add":"Add accounts to trusted list - one account per line",    
    "remove":"Remove accounts from trusted list - one account per line",
    "submit_text":"Store trusted list to blockchain for {accountName}",
    "round":"Added in the round n.",
    "account":"Trusted account",
    "no_tl":"There are no trusted accounts listed for the account {accountName}."
  },
  "voteanswerslist":{
    "no_answers":"There is no response yet to the question",
    "round":"Response round",
    "time":"Response time",
    "sender":"Responder",
    "response":"Response json"
  },
  "merchant":{
    "make_payment":"Platobná brána",
    "pay":"Zaptaťte",
    "to_address":"na algorand účet",
    "please":"",
    "network":"Sieť",
    "matching_symbol":"Párovací symbol",
    "network_fee":"Poplatok za prevod",
    "pay_qr":"Zaplatiť pomocou QR kódu",
    "pay_nativewallet":"Zaplatiť cez algo peňaženku",
    "pay_webwallet":"Zaplatiť cez webovú peňaženku",
    "cancel_payment":"Zrušiť platbu",
    "payment_received":"Platba bola prijatá.",
    "go_back_to_merchant":"Ísť späť na webstránku obchodníka",
    "waiting_for_payment":"Čaká sa na platbu. Zaplaťte prosím."
  },
  "gateway":{
    "title":"Free opensource payment gateway without middleman",
    "perex":"Start accepting algo, USDC, USDT or other assets on your website. By accepting cryptocurrency without financial middleman you will increase your sales on your website and increase your profit.",
    "how":"How it works?",
    "create_order":"Create unique matching symbol, eg. order id",
    "redirect_to_gateway":"Redirect user to the gateway",
    "customer_pays":"Customer pays directly to your algorand account",
    "customer_redirected":"Customer will be redirected to your website back after successful payment",
    "use_your_money":"Use your money. You can echange USDT and take USD from the exchange, or you can pay with USDT for other goods or services",
    "security":"Is it secure?",
    "you_make_details":"You make the payment details. You setup the successful and cancel URL address. We notify you when the payment is done. You have to check in your backend where you accept the payment that it has been received and you notify the customer.",
    "opensource":"This project is open source. You can clone, modify it, or run in your private environment for commercial purposes",
    "just_website":"The payment gateway is just HTML website with javascript. No other backend is used other than official algorand nodes.",
    "no_restrictions":"You do not have AWallet account in order to use the gateway.",
    "allowed_parameters":"Allowed parameters",
    "parameters_perex":"You can set any of these parameters. Only required field is the address where you want to receive your payment and transaction amount. The payment address consits of 2 parts. Payment info, and gateway settings. Both fields are base64 encoded separated by /. Please make sure that you url encode them properly. Payment info is the basic algorand protocol address used for example in the QR codes.",
    "payment_info_params":"Allowed parameters",
    "amount":"use the asset base unit. Eg. 1 ALGO = 1000000",
    "fee":"the amount in microalgos. Eg. 0,001 ALGO = 1000",
    "network":"Network for debug purposes",
    "settings_params":"Allowed settings",
    "success":"URL address where to redirect client after successful payment",
    "cancel":"URL address where to redirect client after he clicks cancel payment",
    "example":"Example",
    "turn_on_build_tool":"Turn on the build tool",
    "turn_off_build_tool":"Turn off the build tool",
    "tether":"Tether mainnet",
    "algorand":"Algorand",
    "asset":"You can specify asset id for the transaction",
    "error_transaction":"Please fill in at least account, amount and asset",
    "error_url":"URL address must start with https:// protocol",
    "button":"Generated button",
    "payment_info":"Payment info",
    "callback_configuration":"Callback configuration",
    "link":"Generated link",
    "html_example":"HTML link example"
    
  },
  "receive": {
    "title": "Prijať platbu",
    "address": "Adresa príjemcu platby",
    "amount": "Čiastka",
    "note": "Poznámka",
    "decimals": "Informácia o desatinnej čiarke do kódu",
    "label": "Návrh označenia účtu pre odosielateľa",
    "noteeditable": "Poznámka je editovateľná odosielateľom"
  },
  "acc_overview": {
    "title": "Prehľad účtov",
    "pay": "Odoslať platbu",
    "receive_payment": "Prijať platbu",
    "name": "Názov",

    "address": "Adresa",
    "amount": "Čiastka",
    "amount_without_pending": "Čiastka bez neprijatých odmien",
    "rewards": "Odmeny",
    "pending_rewards": "Neprijaté odmeny",
    "reward_base": "Základ odmien",
    "round": "Číslo bloku",
    "apps_local_state": "Stav lokálnych aplikácií",
    "apps_total_schema": "Celková schéma aplikácií",
    "assets": "Aktíva",
    "created_apps": "Vytvorené aplikácie",
    "status": "Stav",
    "multisignature_threshold": "Multipodpis limit",
    "multisignature_addresses": "Multipodpis adresa",
    "refresh": "Obnoviť",
    "transactions": "Transakcie",
    "no_transactions": "Zatiaľ na účte nie sú vykonané žiadne transakcie alebo sa nenačítali",
    "type": "Typ",
    "time": "Čas",
    "tr_amount": "Čiastka",
    "sender": "Odosielateľ",
    "receiver": "Príjemca",
    "receiver_rewards": "Odmena pre príjemcu",
    "confirmed_round": "Potvrdený blok",
    "fee": "Poplatok",
    "delete":"Vymazať účet",
    "delete_header":"Vymazať účet",
    "delete_confirm":"Ste si istý, že chcete tento účet vymazať?",
    "delete_confirm_button":"Vymazať účet",
    "deleted":"Účet bol vymazaný",
    "asset_optin":"Pridať aktívum",
    "ask_question": "Ask question"
  },
  "optin":{
    "title":"Prijímať aktívum (ASA alebo NFT) na účte",
    "searchById":"Viem ID aktíva",
    "assetId":"ID aktíva",
    "assetName":"Názov aktíva",
    "searchButton":"Nájsť aktívum",
    "assetInfo":"Informácia o aktíve",
    "total":"Celkovo",
    "unit_name":"Jednotka",
    "decimals":"Des. miesta",
    "url":"URL",
    "metadata_hash":"Metadata hash",
    "default_frozen":"Predvolene zamrznuté",
    "creator":"Adresa tvorcu aktíva",
    "manager":"Adresa managera aktíva",
    "clawback":"Adresa clawback účtu",
    "freeze":"Uzamykateľný účet aktíva",
    "reserve":"Rezervný účet aktíva",
    "action":"Akcia",
    "optin_button":"Akceptovať aktívum",
    "dialog_header":"Akceptovať aktívum",
    "optin_confirm":"Ste si istý, že chcete vykonať prevod za poplatok 0.001 algo a umožňniť prijímať aktívum?",
    "optin_confirm_button":"Chcem prijímať aktívum",
    "asset_not_found":"Aktívum nebolo nájdené",
    "asset_opt_in_success":"Úspešne ste sa v sieti prihlásili k prijímaniu aktíva"
  },
  "accounts": {
    "title": "Prehľad účtov",
    "no_accounts": "Nevytvorili ste si zatiaľ žiadny účet. Pokračujte vytvorením účtu alebo importovaním účtu.",
    "account_name": "Názov účtu",
    "amount": "Čiastka",
    "address": "Adresa",
    "pay": "Platba"
  },
  "newacc": {
    "title": "Nový účet",
    "create_basic": "Vytvoriť bežný účet",
    "import_account": "Import bežného účtu",
    "create_multisign_account": "Vytvoriť mulitipodpisový účet",
    "watch_account": "Pridať verejný účet",
    "write_mnemonic": "Zapíšte si 25 slov mnemotechnickej frázy",
    "name": "Interný názov účtu",
    "create_account": "Vytvoriť účet",
    "address": "Adresa",
    "multisig_help": "Multipodpisové účty môžu odoslať prostriedky iba ak limitný počet účtov podpíše transakciu.",
    "select_account_from_list": "Vybrať účet pre podpis zo zoznamu účtov",
    "add_other_accounts": "Pridať ďalšie účtu - jeden účet na riadok",
    "trashold_help": "Vyberte limitný počet účtov ktorý je potrebný pre podpísanie transakcie",
    "create_account_help": "Aby ste si mohli vytvoriť účet, uistite sa, že ste si zapísali mnemotechnickú frázu. Je bezpečné zobraziť frázu teraz?",
    "show_mnemonic": "Zobraziť mnemotechnickú frázu",
    "position_question": "Aké je slovo na pozícii č.",
    "mnemonic_help": "Zapíšte si mnemotechnickú frázu v stanovenom poradí, skopírujte si ju na bezpečné zaheslované miesto alebo si urobte screenshot. Nikomu túto frázu neukazujte. Ďalší krok bude potvrdenie, že ste si frázu uložili správne a po potvrdení správneho slova na konkrétnej pozícii uložíme tento účet do zašifrovanej peňaženky vo Vašom prehliadači. Môžete použiť túto frázu aj bez toho aby ste si ju uložili npr v iných peňaženkách. Uistite sa, že ju nikdy ani o 10 rokov nestratíte.",
    "start_challenge": "Začať test",
    "create_new": "Vytvoriť novú",
    "hide_mnemonic": "Skryť frázu",
    "drop_phrase": "Zahodiť frázu",
    "scan":"Nascanovať z QR",
    "create_vanity":"Účet s vlastnou adresou",
    "vanity_start":"Začne na",
    "vanity_mid":"Obsahuje",
    "vanity_end":"Končí na",
    "vanity_count":"Počet otestovaných účtov",
    "vanity_button_start":"Nájsť účet",
    "vanity_button_stop":"Stop",
    "vanity_workers":"Počet vlákien",
    "vanity_use":"Použiť tento účet",
    "auto_lock_off":"Auto zamykanie peňaženky je vypnuté"
  },
  "import": {
    "title": "Import peňaženky",
    "wallet_name": "Názov peňaženky",
    "wallet_file": "Súbor peňaženky",
    "import_wallet_button": "Importovať peňaženku",
    "help": "Môžete si importovať vyexportovanú peňaženku. Heslo ostáva rovnaké ako v predchádzajúcej peňaženke. Peňaženka sa dá exportovať v nastaveniach, kde sa dá zmeniť aj heslo."
  },
  "acc_type": {
    "basic_account": "Bežný účet",
    "multisig_account": "Multipodpisový účet",
    "public_account": "Verejný účet"
  },
  "pay": {
    "title": "Nová platba - od",
    "toggle_camera": "Zapnúť/Vypnúť kameru",
    "note_is_b64": "Poznámka je v base64. Ak vložíte poznámku v base64, odošle sa ako raw data pri vytváraní transakcie.",
    "selected_account": "Vybraný účet",
    "multisig_account": "Multipodpisový účet",
    "create_proposal": "Vytvoriť návrh",
    "sign_proposal": "Podpísať & odoslať návrh",
    "signature_from_friend": "Zadajte sem podpis od Vášho priateľa",
    "load_multisig_data": "Načítať údaje z podpisu",
    "pay_to_wallet": "Zaplatiť na účet z peňaženky",
    "pay_to_other": "Zaplatiť na iný účet",
    "store_other_help": "Je lepšie si uložiť účet do zoznamu adries pred vykonaním platby",
    "amount": "Čiastka",
    "fee": "Poplatok",
    "note": "Poznámka",
    "review_payment": "Náhľad platby",
    "review_payment_help": "Skontrolujte si poctivo platobné údaje. Platba na nevalidný účet sa nedá vrátiť späť.",
    "from_account": "Z účtu",
    "pay_to": "Zaplatiť na účet",
    "total": "Celková čiastka",
    "transaction_details": "Detaily transakcie",
    "type": "Typ",
    "name": "Názov",
    "first_round": "Prvý blok",
    "last_round": "Posledný blok",
    "genesis": "GenesisID",
    "tag": "Tag",
    "to_account": "Na účet",
    "signatures": "Podpisy",
    "signed": "Podpísaný",
    "not_signed": "Nepodpísaný",
    "sign_with": "Podpísaný s",
    "sign": "Podpísaný",
    "send_to_other_signators": "Pošlite ďalším podpisovateľom",
    "send_to_network": "Odoslať transakciu do siete",
    "state_sending": "Odosielam prevod validátorom do siete",
    "state_sent": "Platba bola odoslaná do siete. Tx: ",
    "state_waiting_confirm": "Čaká sa na potvrdenie transakcie v bloku.",
    "state_confirmed": "Potvrdenie bolo prijaté. Vaša platba je v bloku č.",
    "transaction": "Transakcia",
    "error": "Chyba",
    "asset": "Aktívum",
    "state_error_not_sent": "Platba pravdepodobne nedorazila do siete. Máte prístup k internetu? Skontroolujte si stav na účte.",
    "set_max":"Nastaviť maxium"
  },
  "settings": {
    "title": "Nastavenia",
    "server": "Server",
    "environment": "Prostredie",
    "mainnet": "Mainnet",
    "testnet": "Testnet",
    "devnet": "Reach Devnet",
    "sandbox": "Sandbox",
    "host": "hostiteľ",
    "token": "token",
    "language": "Jazyk",
    "pass": "Heslo k peňaženke",
    "oldpass": "Staré heslo",
    "newpass": "Nové heslo",
    "repeatpass": "Nové heslo - kontrola",
    "backup": "Záloha peňaženky",
    "backup_help": "Môžete si zazálohovať peňaženku alebo si ju otvoriť v inom počítači.",
    "create_backup": "Vytvoriť zálohu",
    "download": "Stiahnuť",
    "delete": "Vymazať peňaženku",
    "strength": "Sila hesla",
    "updated_password": "Heslo bolo upravené",
    "custom":"Vlastné nastavenie serverov",
    "protocol_title":"Registrácia protokolu",
    "protocol_button":"Zaregistrovať protokol web+algorand://",
    "protocol_change_success":"Protokol bol úspešne zaregistrovaný"
  },
  "transaction": {
    "title": "Detail transakcie",
    "tr_id": "ID transakcie",
    "tr_type": "Typ transakcie",
    "note": "Poznámka",
    "note_raw": "Dáta",
    "note_decoded": "Dekódovaná poznámka",
    "tr_close_rewards": "Uzavretá odmena",
    "closing_amount": "Uzavretá čiastka",
    "confirmed_round": "Potvrdený blok",
    "fee": "Poplatok",
    "first_valid": "Prvý platný blok",
    "genesis_id": "Genesis id",
    "genesis_hash": "Genesis hash",
    "intra_round": "Offset interné čísla bloku",
    "last_valid": "Posledný platný",
    "payment_tr": "Transakcia platby",
    "amount": "Čiastka",
    "close_amount": "Uzavretá čiastka",
    "receiver": "Príjemca",
    "receiver_rewards": "Odmena pre príjemcu",
    "round_time": "Čas vytvorenia bloku",
    "sender": "Odosielateľ",
    "sender_rewards": "Odmena pre odosielateľa",
    "signature": "Podpis",
    "created_asset": "Vytvorené aktívum",
    "asset_name": "Názov aktíva",
    "asset_creator": "Tvorca aktíva",
    "asset_manager": "Manager aktíva",
    "asset_reserve": "Rezervný účet aktíva",
    "asset_freeze": "Uzamykateľný účet aktíva",
    "asset_clawback": "Účet clawback",
    "asset_unitName": "Jednotka",
    "asset_total": "Celkový počet aktíva",
    "asset_decimals": "Počet desatinných miest aktíva",
    "asset_defaultfrozen": "Predvolené je zamknuté",
    "asset_url": "URL adresa aktíva",
    "asset_metadata": "Metadata aktíva",
    "time": "Čas",
    "group":"ID skupiny transakcií"
  },
  "privacy": {
    "title": "Zásady ochrany osobných údajov",
    "q1": "Aké osobné informácie o mne zhromažďujete?",
    "a1": "Žiadne. Okrem IP adresy a prehliadača, ktorý je uložený v protokoloch pre každú internetovú službu. Nezhromažďujeme žiadnu formu súkromných informácií, ako je vaše meno, e-mail alebo adresa.",
    "q2": "Čo ukladáte do cookies? ",
    "a2": "Nič. Nepoužívame cookies. Na ukladanie vašej peňaženky používame trvalé miestne úložisko s názvom indexdb.",
    "q3": "Ako zhromažďujete analytické informácie?",
    "a3": "Nezhromažďujeme. Nepoužívame žiadnu formu analytických služieb, napr. Google Analytics, aby ste sa cítili bezpečnejšie. Web je čisto html s javascriptom a vašu IP adresu poznáme iba z requestov na server. V závislosti od Vašich nastavení a Vášho servera algod alebo indexer môže aj vybraný server spoznať vašu adresu IP.",
    "q4": "Ovplyvňuje použitie tejto služby reklamy ktoré vidím?",
    "a4": "Nie. Komerčné servery od nás nemajú žiadne informácie o žiadnom použití. Jediným spôsobom, ako môže napríklad spoločnosť Google vedieť, že túto službu používate, je prístup na tieto stránky z vyhľadávania Google.",
    "q5": "Kto poskytuje túto službu?",
    "a5": "Scholtz & Company, jsa has created this service. IČO: 51 882 272, IČ DPH: SK2120828105. Ak to beží na oficiálnych webových stránkach poskytovaných spoločnosťou a-wallet.net je tiež poskytovateľom služieb. Spoločnosť nezodpovedá za žiadne služby ani chyby v aplikácii."
  },
  "assetcreate": {
    "title": "Nové NTF / ASA",
    "show_advanced": "Zobraziť pokročilý formulár",
    "assetName": "Názov aktíva",
    "creator": "Adresa tvorcu",
    "hide_advanced": "Skry pokročilý formulár",
    "creator_not_found": "Najskôr si vytvorte adresu prosím",
    "default_fronzen": "Zmrazené: Pred uskutočnením transakcie bude potrebné zrušiť zmrazenie používateľských účtov",
    "decimals": "Desatinné miesta: celé číslo desatinných miest pre výpočet jednotky aktíva",
    "totalIssuance": "Celkový počet aktíva k dispozícii v obehu",
    "unitName": "Názov jednotky aktíva",
    "assetURL": "URL adresa smerujúca na miesto ktoré popisuje aktívum",
    "assetMetadataHash": "Hash aktíva. Dĺžka 32 znakov.",
    "manager": "Manager môže upraviť adresy managera, rezervy, uzamykajúceho účtu a clawback účtu pre aktívum.",
    "reserve": "Rezervná adresa: Zadaná adresa sa považuje za rezervu majetku (nemá žiadne zvláštne privilégiá, má iba informačný charakter)",
    "freeze": "Uzamykajúci účet: Zadaná adresa môže zmraziť alebo zrušiť zmrazenie pozícií majetku používateľa",
    "clawback": "Clawback účet: Zadaná adresa môže zrušiť držbu prostriedkov používateľa a poslať ich na iné adresy",
    "note": "Poznámka uvedená v transakcii vytvorenia",
    "create_button": "Vytvoriť NFT / ASA"
  },

  "faq": {
    "title": "Často kladené otázky",
    "q1": "O peňaženke",
    "a1": "Aplikáciu peňaženky Algo pôvodne vytvorila spoločnosť Scholtz & Company, jsa. Hlavnými výhodami jeho používania je otvorený zdrojový kód, čisté html, kompatibilný s korporátnymi požiadavkami a zabezpečenie.",
    "q2": "Ako sú zabezpečené moje údaje v peňaženke?",
    "a2": "Vaše údaje sú iba v prehliadači. Sú uložené v trvalom úložisku indexdb. Každá peňaženka je šifrovaná pomocou aes256 pomocou hesla peňaženky. Ak nastavíte dostatočne silné heslo, Vaša peňaženka nebude nikdy prelomená hrubou silou. Heslo neopustí váš počítač a nachádza sa iba v medzipamäti prehliadača. Po 5 minútach od prihlásenia je cache zničená.",
    "q3": "Ide moje heslo k peňaženke na internet?",
    "a3": "Nikdy",
    "q4": "Ako môžem zmeniť heslo k peňaženke?",
    "a4_1": "Prihláste sa a vstúpte do svojej peňaženky",
    "a4_2": "Prejdite do nastavení",
    "a4_3": "Zadajte staré heslo, nové heslo a potvrďte nové heslo",
    "a4_4": "Odošlite formulár a vaše heslo bude zmenené",
    "q5": "Môžem presunúť svoju peňaženku do iného počítača?",
    "a5_1": "Prihláste sa a vstúpte do svojej peňaženky",
    "a5_2": "Prejdite do nastavení",
    "a5_3": "Prejdite do sekcie zálohovanie a uložte svoje údaje do miestneho súboru",
    "a5_4": "V druhom počítači prejdite do sekcie nová peňaženka, importujte peňaženku zo stiahnutého súboru a nahrajte do druhého prehľadávača",
    "a5_5": "Prístup do peňaženky je s rovnakým heslom ako predtým. Heslo si môžete zmeniť v nastaveniach.",
    "q6": "Môžem skontrolovať váš zdrojový kód?",
    "a6": "Áno. https://github.com/scholtz/wallet/ Táto peňaženka bola vytvorená ako otvorený zdroj a je možné ju bezplatne používať, kopírovať alebo upravovať aj na komerčné účely. Ak sa môžete na projekte zúčastniť, buďte prosím láskaví a vytvorte pull request v oficiálnom gite so svojím vylepšením.",
    "q7": "Ako môžem projektu pomôcť?",
    "a7_1": "Príspevok na algo adresu P65LXHA5MEDMOJ2ZAITLZWYSU6W25BF2FCXJ5KQRDUB2NT2T7DPAAFYT3U pomôže projektu pokračovať. Projekt nebol financovaný z Algorand inc ani Algorand foundation.",
    "a7_2": "Ak chcete preložiť projekt alebo pomôcť s prekladom, prejdite na stránku https://github.com/scholtz/wallet/tree/master/src/locales, skopírujte prekladový súbor do svojho jazyka a vytvorte pull request.",
    "a7_3": "Ak ste programátor, skontrolujte kód alebo nám pomôžte akýmkoľvek spôsobom akým uznáte za vhodné aby bol tento projekt lepší.",
    "q8": "Ako vytvoriť účet s viacerými podpismi?",
    "a8_1": "Najskôr vytvorte účty pomocou súkromných kľúčov alebo vytvorte verejné účty. Je lepšie dať im mená, ktoré si pamätáte.",
    "a8_2": "Prejdite na vytvorenie novej adresy a vyberte adresu s viacerými podpismi.",
    "a8_3": "Vyberte všetky účty, ktoré môžu podpisovať transakcie, a vyberte počet podpisov, ktoré sú minimálne na to, aby sieť mohla akceptovať multipodpisovú platbu.",
    "a8_4": "Po vytvorení účtu s viacerými podpismi k nemu získate prístup zo stránky so zoznamom účtov."
  }
}
