<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <h1>Merge multiple accounts to one</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>