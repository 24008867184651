{
  "404": {
    "header": "Error 404 - Page not found",
    "text": "Website you are looking for has not been found."
  },
  "footer": {
    "text": "Algo Wallet"
  },
  "login": {
    "new_wallet": "New wallet",
    "new_wallet_name": "Wallet name",
    "new_wallet_pass": "Wallet password",
    "new_wallet_button_create": "Create wallet",
    "new_wallet_button_import": "Import wallet",
    "new_wallet_help1": "Your wallet will be stored in your browser. A password is required to open your wallet, see its accounts and sign transactions.",
    "new_wallet_help2": "We recommend setting a password with more than 12 characters, using a lower case letter, an uppercase letter, a number and a special character.",
    "open_wallet": "Open wallet",
    "select_wallet": "Select wallet",
    "wallet_password": "Wallet password",
    "strength": "Strength",
    "new_wallet_button_open": "Open wallet",
    "basic_usage":"Basic usage",
    "tether_usage":"How to use Tether (USDt)",
    "source_code":"AWallet source code"
  },
  "global": {
    "go_back": "Go back",
    "go_home": "Go home",
    "copy_address": "Copy address",
    "copied_to_clipboard": "Copied to clipboard",
    "stop_camera":"Stop camera",
    "cancel":"Cancel",
    "yes":"Yes",
    "no":"No",
    "loading":"Loading.."
  },
  "navbar": {
    "logo": "A Wallet",
    "toggle_nav": "Toggle navigation",
    "list_accounts": "List my accounts",
    "new_account": "New account",
    "settings": "Settings",
    "asset": "Asset",
    "asset_create": "Create NTF",
    "faq": "FAQ",
    "privacy_policy": "Privacy policy",
    "recurring": "Recurring investment",
    "logout": "Logout",
    "vote":"Voting"
  },
  "votemenu": {
    "overview": "Overview",
    "my_questions": "My questions",
    "ask_question": "Ask question",
    "delegation": "Delegation",
    "answers": "Answers",
    "trusted_list": "Trusted list",
    "governance":"Governance",
    "ams01":"Knowledge-based voting - AMS-0001"
  },
  "govtoolsmenu": {
    "gen": "Generate accounts",
    "distribute": "Distribute algo 1->N",
    "optin": "Opt in for the governance",
    "pay": "Mass pay N->1",
    "merge": "Merge accounts N->1"
  },
  "voteask":{
    "title":"Ask question through blockchain",
    "question_title":"Question title",
    "title_placeholder":"This will show up in the table list of questions",
    "question_text":"Text of the question",
    "url":"URL address with more question details (start with https://)",
    "url_placeholder":"Add here url address where people can find more information about the question",
    "max_round":"Number of blocks since now until the question will be closed",
    "calculated_block":"Max block",
    "calculated_time":"Estimated time",
    "code":"Code",
    "response_text":"Text of the response option",
    "remove_response":"Remove option"   ,
    "responses_help":"People will answer your question by voting for specific responses. Please provide codes and texts of 2-N responses.",
    "add_response":"Add new response",
    "category":"Question category",
    "category_placeholder":"Knowledge based category vote delegation in effect",
    "submit_question":"Ask question using account {accountName}"    
  },
  "votedelegate":{
    "title":"Voting power delegation",
    "intro1":"You can delegate your voting power to someone you trust. It may be your friend or some public person.",
    "intro2":"If you have good friend in finance, you can delegate him voting power in finance category. If he does not vote for questions directly, he might delegate your voting power and his voting power according his wisdom to wise person he know. When the wise person votes, he uses your voting power and he represents your opinions. You can always vote directly in all matters even if you have the delegation. If you vote directly, your voting power stays only at your discrete decision.",
    "intro3":"You can split your voting power to several friends. You set the weight of your voting power distribution in points. If you set 50 points for first friend and 100 points for second friend, first friend will have 50/150 = 33% of your voting power. Second friend will have the rest 100/150 = 67% of your voting power.",
    "intro4":"The category 'any' is the fallback category. If category delegation for specific question is not defined, the any category will be used if defined.",
    "category_any":"Any category",
    "add_account":"Add account",
    "wallet_address":"Wallet address",
    "delegate":"Add account to delegation",
    "category":"Delegate specific category",
    "category_placeholder":"You can specify your delegation in knowledge based category.. eg IT, finance, governance",
    "add_category":"Add specific category",
    "submit_help":"By clicking the button below, you will store your delegation preferences to the blockchain.",
    "submit_text":"Store delegation to the blockchain for {accountName}"
  },
  "voteoverview":{
    "title":"Overview"
  },
  "votequestions":{
    "title":"Questions"
  },
  "votequestionlist":{
    "question_title":"Question title",
    "no_questions":"Be the first one to ask a question",
    "question_text":"Question text",
    "round":"Placed at round",
    "maxround":"Open until round",
    "time":"Time",
    "category":"Category",
    "sender":"Questioner",
    "list":"List all questions",
    "id":"Question id",
    "current_round":"Current round",
    "round_time":"Time of the round",
    "url":"URL",
    "options":"Options",
    "voting_closed":"Voting has been closed",
    "latest_response":"Latest vote for the account {accountName} is selected",
    "vote_button":"Vote with account {accountName}",
    "sum_trusted":"Number of accounted votes",
    "sum_coins":"Sum of accounted coins",
    "trusted_list_results":"Trusted list result",
    "hypercapitalism_results":"1 coin 1 vote results",
    "vote_help":"If you want to vote, select your preferences please. You can assign your preferences for each answer with rating from zero to 100 points. If you want to select only one answer, please give it 100 points, and other answers to zero points. If you do not know, you do not have to vote, or you can assign all answers the equal number of points. If you vote for one answer in 100 points, and other answer 20 points, your voting power for first answer will be 100/120 = 83% and voting power distribution for second answer will be 17%. If you assign all options 100 points, your voting power distribution will be the same as if you assign all options 1 point.",
    "check_results":"Check results"
  },
  "votetl":{
    "title":"Trusted list management",    
    "help1":"Questioner can manage list of trusted accounts on this page. Each person may have only one trusted account. The management of this must be provided by the questioner. After the voting will be finished, there are two calculation of votes - one person one vote, and one coin one vote. The voting system does not interpret what the result of voting means. The voting system just calculates the results of votings according to the voting system rules, eg the delegation of votes, proportion of votes, validity of votes and more.",
    "add":"Add accounts to trusted list - one account per line",    
    "remove":"Remove accounts from trusted list - one account per line",
    "submit_text":"Store trusted list to blockchain for {accountName}",
    "round":"Added in the round n.",
    "account":"Trusted account",
    "no_tl":"There are no trusted accounts listed for the account {accountName}."
  },
  "voteanswerslist":{
    "no_answers":"There is no response yet to the question",
    "round":"Response round",
    "time":"Response time",
    "sender":"Responder",
    "response":"Response json"
  },
  "merchant":{
    "make_payment":"Payment gateway",
    "pay":"Pay",
    "to_address":"to the algorand address",
    "please":"please",
    "network":"Network",
    "matching_symbol":"Matching symbol",
    "network_fee":"Network fee",
    "pay_qr":"Pay using QR code",
    "pay_nativewallet":"Pay using algorand wallet",
    "pay_webwallet":"Pay using web wallet",
    "cancel_payment":"Cancel payment",
    "payment_received":"Payment received",
    "go_back_to_merchant":"Go back to merchant",
    "waiting_for_payment":"Waiting for the payment"
  },
  "gateway":{
    "title":"Free opensource payment gateway without middleman",
    "perex":"Start accepting algo, USDC, USDT or other assets on your website. By accepting cryptocurrency without financial middleman you will increase your sales on your website and increase your profit.",
    "how":"How it works?",
    "create_order":"Create unique matching symbol, eg. order id",
    "redirect_to_gateway":"Redirect user to the gateway",
    "customer_pays":"Customer pays directly to your algorand account",
    "customer_redirected":"Customer will be redirected to your website back after successful payment",
    "use_your_money":"Use your money. You can exchange USDT and take USD from the exchange, or you can pay with USDT for other goods or services",
    "security":"Is it secure?",
    "you_make_details":"You make the payment details. You setup the successful and cancel URL address. We notify you when the payment is done. You have to check in your backend where you accept the payment that it has been received and you notify the customer.",
    "opensource":"This project is open source. You can clone, modify it, or run in your private environment for commercial purposes",
    "just_website":"The payment gateway is just HTML website with javascript. No other backend is used other than official algorand nodes.",
    "no_restrictions":"You do not have AWallet account in order to use the gateway.",
    "allowed_parameters":"Allowed parameters",
    "parameters_perex":"You can set any of these parameters. Only required field is the address where you want to receive your payment and transaction amount. The payment address consists of 2 parts. Payment info, and gateway settings. Both fields are base64 encoded separated by /. Please make sure that you url encode them properly. Payment info is the basic algorand protocol address used for example in the QR codes.",
    "payment_info_params":"Allowed parameters",
    "amount":"use the asset base unit. Eg. 1 ALGO = 1000000",
    "fee":"the amount in microalgos. Eg. 0,001 ALGO = 1000",
    "network":"Network for debug purposes",
    "settings_params":"Allowed settings",
    "success":"URL address where to redirect client after successful payment",
    "cancel":"URL address where to redirect client after he clicks cancel payment",
    "example":"Example",
    "turn_on_build_tool":"Turn on the build tool",
    "turn_off_build_tool":"Turn off the build tool",
    "tether":"Tether mainnet",
    "algorand":"Algorand",
    "asset":"You can specify asset id for the transaction",
    "error_transaction":"Please fill in at least account, amount and asset",
    "error_url":"URL address must start with https:// protocol",
    "button":"Generated button",
    "payment_info":"Payment info",
    "callback_configuration":"Callback configuration",
    "link":"Generated link",
    "html_example":"HTML link example"
    
  },
  "receive": {
    "title": "Receive payment",
    "address": "Receive payment to address",
    "amount": "Amount",
    "note": "Note",
    "decimals": "Information about decimal place to QR code",
    "label": "Suggested label of the receiver address",
    "noteeditable": "Note is editable by user"
  },
  "acc_overview": {
    "title": "Account overview",
    "pay": "Make new payment",
    "receive_payment": "Receive payment",
    "name": "Name",
    "payment_gateway":"Payment gateway",
    "address": "Address",
    "amount": "Amount",
    "amount_without_pending": "Amount without pending rewards",
    "rewards": "Rewards",
    "pending_rewards": "Pending rewards",
    "reward_base": "Reward base",
    "round": "Round",
    "apps_local_state": "Apps local state",
    "apps_total_schema": "Apps total schema",
    "assets": "Assets",
    "created_apps": "Created apps",
    "status": "Status",
    "multi-signature_threshold": "Multi-signature threshold",
    "multi-signature_addresses": "Multi-signature addresses",
    "refresh": "Refresh",
    "transactions": "Transactions",
    "no_transactions": "No transactions found",
    "type": "Type",
    "time": "Time",
    "tr_amount": "Amount",
    "sender": "Sender",
    "receiver": "Receiver",
    "receiver_rewards": "Receiver rewards",
    "confirmed_round": "Confirmed round",
    "fee": "Fee",
    "delete":"Delete account",
    "delete_header":"Delete account",
    "delete_confirm":"Are you sure you want to delete this account from your wallet?",
    "delete_confirm_button":"Delete this account",
    "deleted":"Account has been deleted",
    "asset_optin":"Opt in to asset",
    "ask_question": "Ask question"
  },
  "optin":{
    "title":"Accept transfers of asset in account",
    "searchById":"I know the asset ID",
    "assetId":"Asset ID",
    "assetName":"Asset Name",
    "searchButton":"Find asset",
    "assetInfo":"Information about the asset",
    "total":"Total",
    "unit_name":"Unit name",
    "decimals":"Decimals",
    "url":"URL",
    "metadata_hash":"Metadata hash",
    "default_frozen":"Default frozen",
    "creator":"Creator address",
    "manager":"Manager address",
    "clawback":"Clawback address",
    "freeze":"Freeze address",
    "reserve":"Reserve address",
    "action":"Action",
    "optin_button":"Opt in",
    "dialog_header":"Opt in to asset",
    "optin_confirm":"Are you sure you want to pay 0.001 algo to opt in to this asset? After you opt in, you will be able to receive this asset to your account.",
    "optin_confirm_button":"I want to opt in",
    "asset_not_found":"Asset not found",
    "asset_opt_in_success":"Successfully opt in for the asset"
  },
  "accounts": {
    "title": "Accounts overview",
    "no_accounts": "No accounts found",
    "account_name": "Account name",
    "amount": "Amount",
    "address": "Address",
    "pay": "Pay"
  },
  "newacc": {
    "title": "New account",
    "create_basic": "Create basic account",
    "import_account": "Import account",
    "create_multisign_account": "Create multi-signature account",
    "watch_account": "Watch account",
    "write_mnemonic": "Write down 25 word recovery phrase",
    "name": "Internal account name",
    "create_account": "Create account",
    "address": "Address",
    "multisig_help": "Multi-signature account can process transactions only if N accounts listed at the account creation sign the transaction.",
    "select_account_from_list": "Select existing accounts in your wallet",
    "add_other_accounts": "Add your friends' accounts - one account per line",
    "trashold_help": "Select how many accounts are required to sign the transaction",
    "create_account_help": "In order to create an account, you will need to safely store the recovery phrase. Is it safe to show the phrase now?",
    "show_mnemonic": "Show recovery phrase",
    "position_question": "What is the word at position n",
    "mnemonic_help": "Write the recovery words down (preserving the order), copy or take a picture of the QR code. Never lose the recovery phrase.",
    "start_challenge": "Confirm the recovery phrase",
    "create_new": "Create new",
    "hide_mnemonic": "Hide recovery phrase",
    "drop_phrase": "Drop phrase",
    "scan":"Scan from QR",
    "create_vanity":"Create vanity account",
    "vanity_start":"Address will start with",
    "vanity_mid":"Address will contain",
    "vanity_end":"Address will end with",
    "vanity_count":"Number of addresses processed",
    "vanity_button_start":"Find the address",
    "vanity_button_stop":"Stop searching",
    "vanity_workers":"Number of threads to use",
    "vanity_use":"Use this account",
    "auto_lock_off":"Auto lock feature is turned off"
  },
  "import": {
    "title": "Import wallet",
    "wallet_name": "Wallet name",
    "wallet_file": "Wallet file",
    "import_wallet_button": "Import wallet",
    "help": "You can import previously exported wallets. Wallet password stays within the exported file, so you can access it after the import with the same password as before. You can change the password after login from the settings page."
  },
  "acc_type": {
    "basic_account": "Basic account",
    "multisig_account": "Multi-signature account",
    "public_account": "Public account"
  },
  "pay": {
    "title": "Make payment - from",
    "toggle_camera": "Toggle camera",
    "note_is_b64": "Note is Base64 encoded. If enabled you can post base64 input in the note field and send it as raw data to network.",
    "selected_account": "Selected account",
    "multisig_account": "Multi-signature account",
    "create_proposal": "Create proposal",
    "sign_proposal": "Sign & send proposal",
    "signature_from_friend": "Please enter signature from your friend here",
    "load_multisig_data": "Load multi-signature data",
    "pay_to_wallet": "Pay to wallet account",
    "pay_to_other": "Pay to other account",
    "store_other_help": "It is better to store tha account to which you are sending money in the address book",
    "amount": "Amount",
    "fee": "Fee",
    "note": "Note",
    "review_payment": "Review payment",
    "review_payment_help": "Please review your payment",
    "from_account": "From account",
    "pay_to": "Pay to account",
    "total": "Total",
    "transaction_details": "Transaction details",
    "type": "Type",
    "name": "Name",
    "first_round": "FirstRound",
    "last_round": "LastRound",
    "genesis": "GenesisID",
    "tag": "Tag",
    "to_account": "To",
    "signatures": "Signatures",
    "signed": "Signed",
    "not_signed": "Not signed",
    "sign_with": "Sign with",
    "sign": "Sign",
    "send_to_other_signators": "Send this data to other signators",
    "send_to_network": "Send to the network",
    "state_sending": "Sending payment to to the network",
    "state_sent": "Payment sent to the network. Tx: ",
    "state_waiting_confirm": "Waiting for network confirmation.",
    "state_confirmed": "Confirmation has been received. Your payment is in the block",
    "transaction": "Transaction",
    "error": "Error",
    "asset": "Asset",
    "state_error_not_sent": "Payment has probably not reached the network. Are you offline? Please check you account",
    "set_max":"Set maxium"
  },
  "settings": {
    "title": "Settings",
    "server": "Server",
    "environment": "Environment",
    "mainnet": "Mainnet",
    "testnet": "Testnet",
    "devnet": "Reach Devnet",
    "sandbox": "Sandbox",
    "host": "host",
    "token": "token",
    "language": "Language",
    "pass": "Wallet password",
    "oldpass": "Old password",
    "newpass": "New password",
    "repeatpass": "New password - repeat",
    "backup": "Backup wallet",
    "backup_help": "You can backup wallet and import to other computer.",
    "create_backup": "Create backup",
    "download": "Download",
    "delete": "Delete the wallet",
    "strength": "Strength",
    "updated_password": "Password has been updated",
    "custom":"Custom server settings",
    "protocol_title":"Register protocol handler",
    "protocol_button":"Register web+algorand:// handler",
    "protocol_change_success":"Successfully updated the protocol handler"
  },
  "transaction": {
    "title": "Transaction detail",
    "tr_id": "Transaction ID",
    "tr_type": "Transaction type",
    "note": "Note",
    "note_raw": "Raw data",
    "note_decoded": "Decoded data",
    "tr_close_rewards": "Close rewards",
    "closing_amount": "Closing amount",
    "confirmed_round": "Confirmed round",
    "fee": "Fee",
    "first_valid": "First valid",
    "genesis_id": "Genesis id",
    "genesis_hash": "Genesis hash",
    "intra_round": "Intra round offset",
    "last_valid": "Last valid",
    "payment_tr": "Payment transaction",
    "amount": "Amount",
    "close_amount": "Close amount",
    "receiver": "Receiver",
    "receiver_rewards": "Receiver rewards",
    "round_time": "Round time",
    "sender": "Sender",
    "sender_rewards": "Sender rewards",
    "signature": "Signature",
    "created_asset": "Created asset",
    "asset_name": "Asset name",
    "asset_creator": "Asset creator",
    "asset_manager": "Asset manager",
    "asset_reserve": "Asset reserve",
    "asset_freeze": "Asset freeze",
    "asset_clawback": "Asset clawback",
    "asset_unitName": "Asset unit name",
    "asset_total": "Asset total",
    "asset_decimals": "Asset decimals",
    "asset_defaultfrozen": "Asset default frozen",
    "asset_url": "Asset url",
    "asset_metadata": "Asset metadata",
    "time": "Time",
    "group":"Group ID"
  },
  "privacy": {
    "title": "Privacy policy",
    "q1": "What private information about me do you collect?",
    "a1": "None. We do not collect any private information. The only information about you we use is your IP address that you need to interact with any internet website.",
    "q2": "What do you store in the cookies?",
    "a2": "Nothing. We do not use cookies.",
    "q3": "How do you collect analytics (e.g. Google Analytics) information?",
    "a3": "We do not. To protect your privacy, we do not use any form of analytics.",
    "q4": "Does use of this service affect commercial advertisements I see?",
    "a4": "No. Commercial servers do not have any information from us about any usage.",
    "q5": "Who provides this service?",
    "a5": "Scholtz & Company, jsa has created this service. Company Id: 51 882 272, VAT ID: SK2120828105, based in Bratislava, Slovakia."
  },
  "assetcreate": {
    "title": "New NTF / ASA",
    "show_advanced": "Show advanced form",
    "assetName": "Friendly name",
    "creator": "Creator address",
    "hide_advanced": "Hide advanced form",
    "creator_not_found": "Create address or import address first",
    "default_fronzen": "Frozen : User accounts will need to be unfrozen before transacting",
    "decimals": "Decimals: integer number of decimals for asset unit calculation",
    "totalIssuance": "Total number of this asset available for circulation",
    "unitName": "Used to display asset units to user",
    "assetURL": "Optional string pointing to a URL relating to the asset",
    "assetMetadataHash": "Optional hash commitment of some sort relating to the asset. 32 character length.",
    "manager": "Manager can modify manager, reserve, freeze, and clawback account for the asset.",
    "reserve": "Reserve address: Specified address is considered the asset reserve (it has no special privileges, this is only informational)",
    "freeze": "Freeze address: Specified address can freeze or unfreeze user asset holdings",
    "clawback": "Clawback address: Specified address can revoke user asset holdings and send them to other addresses",
    "note": "Note set in the create transaction",
    "create_button": "Create NFT / ASA"
  },

  "faq": {
    "title": "Frequently asked questions",
    "q1": "About the wallet",
    "a1": "Algo wallet application has been initially created by Scholtz & Company, jsa. Open source, html only, corporate friendly, and security are the main advantages for its usage. Algorand logo, word algo as well word algorand are trademarked by the algorand inc., and are used with algorand media guidelines https://www.algorand.com/about/media-kit/ .",
    "q2": "How is my wallet data secured?",
    "a2": "Your data is in the browser only. It is stored in persistent index db. Every wallet is encrypted using aes256 with your password. If you make strong enough password, your wallet will never be brute-forced. The password does not leave your computer and resides only in the browser cache. After 5 minutes after the login the cache is destroyed.",
    "q3": "Does my wallet password go to the internet?",
    "a3": "Never",
    "q4": "How can I change the wallet password?",
    "a4_1": "Login and access your wallet",
    "a4_2": "Navigate to settings.",
    "a4_3": "Type old password, new password, and confirm new password.",
    "a4_4": "Submit form and your password will be changed.",
    "q5": "Can I move my wallet to other computer?",
    "a5_1": "Login and access your wallet",
    "a5_2": "Navigate to settings.",
    "a5_3": "Go to section backup and save your data to the local file.",
    "a5_4": "In the other computer go to new wallet, import wallet, and upload the downloaded file to the other browser storage.",
    "a5_5": "Access the wallet with the same password as before. You can change the password in settings.",
    "q6": "Can I check your source code?",
    "a6": "Yes. https://github.com/scholtz/wallet/ This wallet was made open source and is free to be used, copied or modified even for commercial purposes. If you are able to participate in the project, please be so kind and create a pull request with your improvement.",
    "q7": "How can I help the project?",
    "a7_1": "Donation to algorand address P65LXHA5MEDMOJ2ZAITLZWYSU6W25BF2FCXJ5KQRDUB2NT2T7DPAAFYT3U will keep the project going. The project has not been funded from algorand inc nor algorand foundation.",
    "a7_2": "To translate project or help with the translation, please go to  https://github.com/scholtz/wallet/tree/master/src/locales, copy the translation file to your language and make a pull request.",
    "a7_3": "If you are a programmer, please do a code review, or help us in any way you think this project should be better.",
    "q8": "How to make multi-signature account?",
    "a8_1": "First create the accounts with the private keys or create public accounts. It is better to give them names you will remember its purpose.",
    "a8_2": "Go to create new address and select multi-signature address.",
    "a8_3": "Select all accounts which can sign the transactions and select how  many signatures are minimum for the network to accept the multisig payment.",
    "a8_4": "After you create the multi-signature account, you can access it from the accounts list page."
  }
}
