<template>
  <footer class="bg-dark text-light">
    <a
      v-if="t"
      @click="prolong"
      class="btn btn-xs btn-light float-end m-2"
      :style="'background:' + this.b"
    >
      {{ t }}
    </a>
    <div class="text-center m-3">
      Copyright &copy; 2021 DREM Dao Ventures BBLLC
    </div>
    <div class="container">
      <div class="row m-3">
        <div class="col">
          <b>Real estate companies</b>
          <div>
            <a
              href="https://globdrem.com/rec/list-on-drem.html"
              class="link-light"
              >List your property on DREM</a
            >
          </div>
          <div>
            <a
              href="https://docs.globdrem.com/fundamentals/rec-asset-liquidity"
              class="link-light"
              >Distribute rental income</a
            >
          </div>
        </div>
        <div class="col">
          <b>Market makers</b>
          <div>
            <a
              href="https://docs.globdrem.com/fundamentals/market-liquidity"
              class="link-light"
              >Earn money by providing liquidity</a
            >
          </div>
        </div>
        <div class="col">
          <b>DAO Investors</b>
          <div>
            <a href="https://docs.globdrem.com/dao/our-story" class="link-light"
              >Invest to DREM Dao Ventures BBLLC</a
            >
          </div>
          <div>
            <a
              href="https://docs.globdrem.com/dao/our-story#timeline"
              class="link-light"
              >Roadmap</a
            >
          </div>
          <div>Financial statements</div>
        </div>
        <div class="col">
          <b>Marketplace</b>
          <div>
            <a href="https://docs.globdrem.com/dao/contact" class="link-light"
              >Contact</a
            >
          </div>
          <div>
            <a
              href="https://docs.globdrem.com/fundamentals/fees"
              class="link-light"
              >Fees</a
            >
          </div>
          <div>
            <a
              href="https://docs.globdrem.com/dao/terms-and-conditions"
              class="link-light"
              >Terms and conditions</a
            >
          </div>
          <div>
            <a
              href="https://docs.globdrem.com/dao/privacy-policy"
              class="link-light"
              >Privacy policy</a
            >
          </div>
          <div>
            <a href="https://docs.globdrem.com/" class="link-light"
              >Documentation</a
            >
          </div>
        </div>
      </div>
      <div class="row m-3">
        <div class="col disclaimer">
          This site is operated by DREM Dao Ventures BBLLC, which is not a
          registered broker-dealer or investment advisor. DREM Dao Ventures does
          not give investment advice, endorsement, analysis or recommendations
          with respect to any securities. Nothing on this website should be
          construed as an offer to sell, solicitation of an offer to buy or a
          recommendation for any security by DREM Dao Ventures. or any third
          party. You are solely responsible for determining whether any
          investment, investment strategy, security or related transaction is
          appropriate for you based on your personal investment objectives,
          financial circumstances and risk tolerance. You should consult with
          licensed legal professionals and investment advisors for any legal,
          tax, insurance or investment advice. All securities listed here are
          being offered by, and all information included on this site is the
          responsibility of, the applicable issuer of such securities. DREM Dao
          Ventures does not guarantee any investment performance, outcome or
          return of capital for any investment opportunity posted on this site.
          By accessing this site and any pages thereof, you agree to be bound by
          the Terms of Service and Privacy Policy. Past performance is no
          guarantee of future results. Any historical returns, expected returns
          or probability projections may not reflect actual future performance.
          All investments involve risk and may result in partial or total loss.
          Investors of DREM Dao Ventures understand and acknowledge 1) that
          investing in real estate, like investing in other fields, is risky and
          unpredictable; 2) that the real estate industry has its ups and downs;
          3) that the real property you invest in might not result in a positive
          cash flow or perform as you expected; and 4) that the value of any
          real property you invest in may decline at any time and the future
          property value is unpredictable. Before making an investment decision
          with respect to any offering, prospective investors are advised to
          review all available information and consult with their tax, legal and
          financial advisors. DREM Dao Ventures does not give investment advice
          or recommendations regarding any offering posted on the website. Any
          investment-related information contained herein has been secured from
          sources that DREM Dao Ventures believes to be reliable, but we make no
          representations or warranties as to the accuracy or completeness of
          such information and accept no liability therefore. Meanwhile,
          hyperlinks to third-party sites, or reproduction of third-party
          articles, do not constitute an approval or endorsement by DREM Dao
          Ventures of the linked or reproduced content.
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import { mapActions } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      t: "",
      b: "white",
    };
  },
  mounted() {
    this.setTime();
    setInterval(
      function () {
        this.setTime();
      }.bind(this),
      1000
    );
  },
  methods: {
    ...mapActions({
      prolong: "wallet/prolong",
      logout: "wallet/logout",
    }),
    setTime() {
      const elapsed = new Date() - this.$store.state.wallet.time;
      const t = 1800000 - elapsed;
      if (t < 60000) {
        const sec = Math.round(t / 1000) % 3;
        if (sec == 0) {
          this.b = "white";
        }
        if (sec == 1) {
          this.b = "red";
        }
        if (sec == 2) {
          this.b = "orange";
        }
      } else {
        this.b = "white";
      }
      if (t < 0) {
        this.logout();
      }
      if (this.$store.state.wallet.isOpen) {
        this.t = moment(t).format("mm:ss");
      } else {
        if (this.t) {
          // unauth
          this.logout();
        }
        this.t = "";
      }
    },
  },
};
</script>

<style scoped>
.disclaimer {
  font-size: 0.7em;
}
</style>
