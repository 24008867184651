<template>
  <PublicLayout>
    <h1>Recurring investment</h1>
    <p>
      Create a standing order, and we will invest your money into the project with the best return.
    </p>
  </PublicLayout>
</template>
<script>
import PublicLayout from "../../layouts/Public.vue";
import { mapActions } from "vuex";
export default {
  components: {
    PublicLayout,
  },
  async mounted() {
    await this.prolong();
  },
  methods: {
    ...mapActions({
      axiosGet: "axios/get",
      algodexBuy: "algodex/algodexBuy",
      algodexCancelBuy: "algodex/cancelBuy",
      algodexHitAllBuy: "algodex/hitAllBuy",
      algodexHitBuyPartial: "algodex/hitBuyPartial",
      algodexIncreaseBuyVolume: "algodex/increaseBuyVolume",
      algodexSell: "algodex/algodexSell",
      algodexHitAllSell: "algodex/hitAllSell",
      algodexCancelSell: "algodex/cancelSell",
      algodexHitSellPartial: "algodex/hitSellPartial",
      algodexIncreaseSellVolume: "algodex/increaseSellVolume",
      waitForConfirmation: "algod/waitForConfirmation",
      accountInformation: "algod/accountInformation",
      getAsset: "indexer/getAsset",
      prolong: "wallet/prolong",
      openError: "toast/openError",
    }),
  },
};
</script>
