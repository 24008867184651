{
  "404": {
    "header": "404-es hiba - az oldal nem található",
    "text": "A keresett webhely nem található."
  },
  "footer": {
    "text": "Algo pénztárca"
  },
  "login": {
    "new_wallet": "Új pénztárca",
    "new_wallet_name": "Pénztárca név",
    "new_wallet_pass": "Pénztárca jelszó",
    "new_wallet_button_create": "Pénztárca létrehozása",
    "new_wallet_button_import": "Pénztárca importálása",
    "new_wallet_help1": "A pénztárca a böngészőn belül fog tárolódni. A pénztárca megnyitásához, a pénztárcán belüli számlák kijelzéséhez és a tranzakciók aláírásához egy jelszóra van szükség.",
    "new_wallet_help2": "Azt tanácsoljuk, hogy 12 karakternél hosszabb jelszót használj. A jelszóban legyenek kisbetűk, nagybetűk és különleges karakterek.",
    "open_wallet": "Pénztárca megnyitása",
    "select_wallet": "Pénztárca kiválasztása",
    "wallet_password": "Pénztárca jelszava",
    "strength": "Erősség",
    "new_wallet_button_open": "Pénztárca megnyitása",
    "basic_usage": "Alapműveletek",
    "tether_usage": "Hogyan használjuk a Tether-t (USDt)",
    "source_code": "AWallet forráskód"
  },
  "global": {
    "go_back": "Vissza",
    "go_home": "Ugrás a kezdőlapra",
    "copy_address": "Cím másolása",
    "copied_to_clipboard": "Átmásolva a vágólapra",
    "stop_camera": "Fényképezőgép leállítása",
    "cancel": "Mégsem",
    "yes": "Igen",
    "no": "Nem",
    "loading":"Betöltés alatt..."
  },
  "navbar": {
    "logo": "AWallet",
    "toggle_nav": "Váltás a navigációban",
    "list_accounts": "Számlák listázása",
    "new_account": "Új számla",
    "settings": "Beállítások",
    "asset": "Eszköz",
    "asset_create": "NTF létrehozás",
    "faq": "GYIK",
    "privacy_policy": "Adatvédelem",
    "recurring": "Ismétlődő befektetés",
    "logout": "Kijelentkezés",
    "vote":"Szavazás"
  },
  "votemenu": {
    "overview": "Áttekintés",
    "my_questions": "Kérdéseim",
    "ask_question": "Kérdésem van",
    "delegation": "Delegálás",
    "answers": "Válaszok",
    "trusted_list": "Bizalmi lista",
    "governance":"Governance",
    "ams01":"Knowledge based voting - AMS-0001"
  },
  "govtoolsmenu": {
    "gen": "Generate accounts",
    "distribute": "Distribute algo 1->N",
    "optin": "Opt in for the governance",
    "pay": "Mass pay N->1",
    "merge": "Merge accounts N->1"
  },
  "voteask":{
    "title":"Kérdezz valamit a blokkláncon keresztül",
    "question_title":"A kérdés címe",
    "title_placeholder":"Ez fog megjelenni a kérdéseket tartalmazó táblázatban",
    "question_text":"A kérdés szövege",
    "url":"URL cím, amely a kérdés további részleteit tartalmazza (kezdd úgy, hogy https://)",
    "url_placeholder":"Itt adható meg egy URL cím, ahol további információ található a kérdésről",
    "max_round":"A jelenlegi blokkhoz képest ennyi blokk múlva lesz a kérdés lezárva",
    "calculated_block":"Max blokk",
    "calculated_time":"Becsült idő",
    "code":"Kód",
    "response_text":"A válaszlehetőség szövege",
    "remove_response":"Válaszlehetőség törlése",
    "responses_help":"A többiek oly módon fognak válaszolni a kérdésedre, hogy szavaznak az egyes lehetőségekre. Légy szíves, add meg a 2-ik..N-ik válasz kódját és szövegét.",
    "add_response":"Új válasz felvétele", 
    "category":"Kérdés kategória",
    "category_placeholder":"Tudás alapú kategória alapján a szavazati jog átruházása van érvényben",
    "submit_question":"Tegyél föl egy kérdést az {accountName} számla segítségével"    
  },
  "votedelegate":{
    "title":"Szavazati jog átruházása",
    "intro1":"A szavazati jogodat átruházhatod más valakire, akiben megbízol. Ez lehet a barátod, vagy egy ismert személy.",
    "intro2":"Ha van egy pénzügyekben jártas jóbarátod, akkor a pénzügyi kategóriában átruházhatod rá a szavazati jogodat. Ha a barátod sem szavaz közvetlenül a kérdésekre, akkor átruházhatja a te szavazati jogodat és a saját szavazati jogát a saját belátása szerint az általa ismert szakértőre. Amikor ez a szakértő szavaz, akkor a te szavazati jogodat használja és a te véleményedet képviseli. Mindig szavazhatsz közvetlen módon, még akkor is, ha átruháztad a szavazati jogodat. Ha közvetlenül szavazol, akkor az adott kérdésben a tiéd marad a szavazati jogod.",
    "intro3":"A szavazati jogodat több barátod között is szétoszthatod. A szavazati jogod megosztásának súlyozását pontokkal tudod kifejezni. Ha az első barátodnak 50 pontot adtál, a másodiknak 100 pontot, akkor az első barátod a szavazati jogod 50/150 = 33 %-val fog rendelkezni. A szavazati jogod maradékát, 100/150 = 67 %-ot a második barátod kapja.",
    "intro4":"A 'Tetszőleges kategória' tartalék kategória. Ha egy adott kérdés esetében nem lett delegálva egyetlen kategória sem, de a 'Tetszőleges kategória' definiálva van, akkor ez lesz használva.",
    "category_any":"Tetszőleges kategória",
    "add_account":"Számla hozzáadása",
    "wallet_address":"Pénztárca cím",
    "delegate":"Számla hozzáadása az átruházáshoz",
    "category":"Egy adott kategória átruházása",
    "category_placeholder":"Az átruházás megadható egy adott tudás alapú kategóriában, pl. IT, pénzügy, irányítás",
    "add_category":"Egy adott kategória megadása",
    "submit_help":"A lenti gomb megnyomásával az átruházással kapcsolatos preferenciák a blokkláncon tárolhatók.",
    "submit_text":"Az {accountName} átruházásainak tárolása a blokkláncon"
  },
  "voteoverview":{
    "title":"Áttekintés"
  },
  "votequestions":{
    "title":"Kérdések"
  },
  "votequestionlist":{
    "question_title":"A kérdés címe",
    "no_questions":"Légy te az első, aki feltesz egy kérdést",
    "question_text":"A kérdés szövege",
    "round":"A kérdés ennél a menetnél lett feltéve:",
    "maxround":"A kérdés eddig a menetig lesz nyitva:",
    "time":"Idő",
    "category":"Kategória",
    "sender":"Kérdező",
    "list":"Az összes kérdés listája",
    "id":"Kérdés azonosító",
    "current_round":"Aktuális menet",
    "round_time":"A menet ideje",
    "url":"URL",
    "options":"Lehetőségek",
    "voting_closed":"A szavazás lezárult",
    "latest_response":"Az {accountName} utolsó szavazata lesz figyelembe véve",
    "vote_button":"Szavazz az {accountName} számlával",
    "sum_trusted":"A figyelembe vett szavazatok száma",
    "sum_coins":"A figyelembe vett érmék összege",
    "trusted_list_results":"Eredmény a megbízható szavazók listája alapján",
    "hypercapitalism_results":"Eredmény az 1 érme 1 szavazat alapján",
    "vote_help":"Ha szavazni szeretnél, akkor légy szíves, add meg a súlyozást. Az egyes válaszoknál nulla és 100 pont közötti súlyozás adható. Ha csak egy választ akarsz kiválasztani, akkor adj neki 100 pontot, a többi válasznak pedig nulla pontot. Ha nem tudod a választ, akkor nem kell szavaznod, illetve az összes válaszhoz azonos pontot rendelhetsz hozzá. Ha az egyik válaszra 100 ponttal szavazol, egy másik válaszra pedig 20 ponttal, akkor az első válaszra a szavazati jogod 100/120 = 83%-ával szavazol, a másodikra 17 %-kal. Ha az összes lehetőséghez 100 pontot rendelsz, akkor a szavazati jogod eloszlása ugyanolyan lesz, mint ha mindegyik lehetőséghez 1 pontot rendeltél volna.",
    "check_results":"Eredmények ellenőrzése"
  },
  "votetl":{
    "title":"A megbízható szavazók listájának kezelése",    
    "help1":"A kérdésfeltevő ezen a lapon kezelheti a megbízható számlák listáját. Egy személyhez csak egy megbízható számla tartozhat. Ennek a listának a kezelése a kérdésfeltevő feladata. A szavazás befejeződése után kétféleképpen lesznek a szavazatok összesítve: 'egy személy egy szavazat' alapján, vagy 'egy érme egy szavazat' alapján. A szavazó rendszer nem értelmezi a szavazás eredményét, csupán kiszámítja a szabályok alapján a szavazás eredményét, vagyis figyelembe veszi pl. a szavazatok átruházását, a rész-szavazatokat, a szavazatok érvényességét stb.",
    "add":"Számla hozzáadása a megbízható szavazók listájához - soronként egy számla",    
    "remove":"Számla eltávolítása a megbízható szavazók listájából - soronként egy számla",
    "submit_text":"Az {accountName} számlához tartozó megbízható szavazók listájának eltárolása a blokkláncon",
    "round":"Az n-ik menetben létrehozva",
    "account":"Megbízható számla",
    "no_tl":"Az {accountName} számlához nincsenek megbízható számlák felvéve."
  },
  "voteanswerslist":{
    "no_answers":"Még nincs válasz a kérdésre",
    "round":"Menetek száma a válaszadáskor",
    "time":"Válaszadás ideje",
    "sender":"Válaszadó",
    "response":"A válaszhoz tartozó json"
  },
  "merchant":{
    "make_payment":"Fizetési gateway",
    "pay":"Fizess",
    "to_address":"az Algorand címnek",
    "please":"kérlek",
    "network":"Hálózat",
    "matching_symbol":"Egyedi szimbólum",
    "network_fee":"Hálózati díj",
    "pay_qr":"Fizetés QR-kód használatával",
    "pay_nativewallet":"Fizetés Algorand pénztárca használatával",
    "pay_webwallet":"Fizetés web-es pénztárca használatával",
    "cancel_payment":"Fizetés törölve",
    "payment_received":"A küldött összeg megérkezett",
    "go_back_to_merchant":"Vissza a kereskedőhöz",
    "waiting_for_payment":"Várakozás a fizetségre"
  },
  "gateway":{
    "title":"Szabadon használható, nyílt forráskódú, közvetítők nélküli fizetési gateway",
    "perex":"Fogadj el a webhelyeden Algót, USDC-t, USDT-t vagy más egyéb pénzeszközt. A kriptovaluták pénzügyi közvetítő nélküli elfogadása növeli a webhely eladásait és a hasznot.",
    "how":"Hogyan működik?",
    "create_order":"Hozz létre egy egyedi azonosító szimbólumot, pl. a megrendelés azonosítót",
    "redirect_to_gateway":"Irányítsd át a felhasználót a gateway-re",
    "customer_pays":"A felhasználó közvetlenül a te Algorand számládra fizet",
    "customer_redirected":"A felhasználó a sikeres fizetési tranzakció után vissza lesz irányítva a webhelyedre",
    "use_your_money":"Használd a pénzedet. Az USDT-t a pénzváltóhelyen USD-re válthatod, vagy az UDST-vel fizetni tudsz más árukért vagy szolgáltatásokért.",
    "security":"Mennyire biztonságos mindez?",
    "you_make_details":"Te határozod meg a fizetés részleteit. Te állítod be a sikeres és a törölt fizetéshez tartozó URL címeket. Értesítést kapsz, ha a fizetés megtörtént. Ellenőrizned kell azon a háttérfelületen, ahol a fizetést elfogadod, hogy megjött-e a pénz, és értesítened kell a vevőt.",
    "opensource":"Ez egy nyílt forráskódú projekt. Klónozható, módosítható, vagy üzleti céllal a saját környezetedben futtatható.",
    "just_website":"A fizetési gateway egy HTML webhely, amely JavaScript-et futtat. A hivatalos Algorand csomópontokon kívül semmilyen más hátteret nem használ.",
    "no_restrictions":"A gateway használatához nem szükséges AWallet számlával rendelkezni.",
    "allowed_parameters":"Megengedett paraméterek",
    "parameters_perex":"A paraméterek bármelyike állítható. Kötelezően megadandó mezők: az a cím, ahol fogadni szeretnéd a fizetséget, és a tranzakció összege. A fizetési cím két részből áll: a fizetési adatból és a gateway beállításokból. Mindkét mező base64 kódolású, és egy / jel választja el őket egymástól. Vigyázz rá, hogy mindkettőt megfelelően kódold. A fizetési adat nem más, mint az az Algorand protokoll cím, amelyet pl. a QR-kódokban használnak.",
    "payment_info_params":"Megengedett paraméterek",
    "amount":"a pénzeszköz alapegységének a használata. Pl. 1 Algo = 1000000",
    "fee":"az összeg mikroalgókban. Pl. 0,001 Algo = 1000",
    "network":"Belövéshez használt hálózat",
    "settings_params":"Megengedett beállítások",
    "success":"URL cím, ahová a kliens a sikeres fizetés után lesz átirányítva",
    "cancel":"URL cím, ahová a kliens a 'fizetés törlése' választása után lesz átirányítva",
    "example":"Példa",
    "turn_on_build_tool":"Turn on the build tool",
    "turn_off_build_tool":"Turn off the build tool",
    "tether":"Tether mainnet",
    "algorand":"Algorand",
    "asset":"You can specify asset id for the transaction",
    "error_transaction":"Please fill in at least account, amount and asset",
    "error_url":"URL address must start with https:// protocol",
    "button":"Generated button",
    "payment_info":"Payment info",
    "callback_configuration":"Callback configuration",
    "link":"Generated link",
    "html_example":"HTML link example"
    
  },
  "receive": {
    "title": "Fizetés fogadása",
    "address": "Fizetés fogadása ezen a címen",
    "amount": "Összeg",
    "note": "Megjegyzés",
    "decimals": "Tizedes jegyek száma a QR-kód számára",
    "label": "A fogadó fél címéhez tartozó címke",
    "noteeditable": "A megjegyzés mezőt a felhasználó szerkesztheti"
  },
  "acc_overview": {
    "title": "Számla áttekintés",
    "pay": "Új kifizetés létrehozása",
    "receive_payment": "Fizetés fogadása",
    "name": "Név",

    "address": "Cím",
    "amount": "Összeg",
    "amount_without_pending": "Összeg a függő jutalmak nélkül",
    "rewards": "Jutalmak",
    "pending_rewards": "Függő jutalmak",
    "reward_base": "A jutalom alapja",
    "round": "Menet",
    "apps_local_state": "App-ok lokális állapota",
    "apps_total_schema": "App-ok teljes sémája",
    "assets": "Eszközök",
    "created_apps": "Létrehozott app-ok",
    "status": "Státusz",
    "multisignature_threshold": "Többszörös aláírási küszöb",
    "multisignature_addresses": "Többszörösen aláírt címek",
    "refresh": "Frissítés",
    "transactions": "Tranzakciók",
    "no_transactions": "Nincs egyetlen tranzakció sem",
    "type": "Típus",
    "time": "Idő",
    "tr_amount": "Összeg",
    "sender": "Küldő",
    "receiver": "Fogadó",
    "receiver_rewards": "A fogadó jutalma",
    "confirmed_round": "Megerősített menetek száma",
    "fee": "Díj",
    "delete": "Számla törlése",
    "delete_header": "Számla törlése",
    "delete_confirm": "Biztosan törölni szeretnéd ezt a számlát a pénztárcából?",
    "delete_confirm_button": "Ennek a számlának a törlése",
    "deleted": "A számla törölve lett",
    "asset_optin": "Pénzeszköz engedélyezés",
    "ask_question": "Kérdésem van"
  },
  "optin": {
    "title": "Egy adott pénzeszköz fogadásának engedélyezése, számla:",
    "searchById": "Tudom, hogy mi a pénzeszköz azonosítója",
    "assetId": "Pénzeszköz azonosító",
    "assetName": "Pénzeszköz név",
    "searchButton": "Pénzeszköz keresés",
    "assetInfo": "Információ a pénzeszközről",
    "total": "Összesen",
    "unit_name": "Egység neve",
    "decimals": "Tizedes jegyek",
    "url": "URL",
    "metadata_hash": "Metaadat hash",
    "default_frozen": "Kezdetben befagyasztva",
    "creator": "Létrehozó címe",
    "manager": "Kezelő címe",
    "clawback": "Visszakövetelő címe",
    "freeze": "Befagyasztó címe",
    "reserve": "Kibocsátó címe",
    "action": "Művelet",
    "optin_button": "Benevezés",
    "dialog_header": "Pénzeszköz fogadásának engedélyezése",
    "optin_confirm": "Biztosan ki akarsz fizetni 0,001 Algót, hogy engedélyezd ennek az eszköznek a fogadásást? Az engedélyezés után képes leszel arra, hogy ilyen eszközt a fogadj a számládon.",
    "optin_confirm_button": "Engedélyezni akarom a pénzeszköz fogadását",
    "asset_not_found": "Nincs ilyen pénzeszköz",
    "asset_opt_in_success": "Sikeresen engedélyezted az eszköz fogadását"
  },
  "accounts": {
    "title": "Számlák áttekintése",
    "no_accounts": "Nincs egyetlen egy számla sem",
    "account_name": "Számla neve",
    "amount": "Összeg",
    "address": "Cím",
    "pay": "Kifizetés"
  },
  "newacc": {
    "title": "Új számla",
    "create_basic": "Egyszerű számla létrehozása",
    "import_account": "Számla importálása",
    "create_multisign_account": "Többszörösen aláírt számla létrehozása",
    "watch_account": "Megfigyelési számla",
    "write_mnemonic": "Írd le a 25 szavas mnemonikus kifejezést",
    "name": "Belső számlanév",
    "create_account": "Számla létrehozás",
    "address": "Cím",
    "multisig_help": "A többszörösen aláírt számla csak akkor dolgozza fel a tranzakciókat, ha a számla létrehozásakor kilistázott N számla aláírja a tranzakciót.",
    "select_account_from_list": "Válassz egy létező számlát a pénztárcádban",
    "add_other_accounts": "Add meg a barátaid számláit - soronként egyet",
    "trashold_help": "Válaszd ki, hogy hány számla szükséges egy tranzakció aláírásához",
    "create_account_help": "A számla létrehozásakor mentsd el a mnemonikot. Biztonságos, ha a kifejezés megjelenítése most történik?",
    "show_mnemonic": "A mnemonik megjelenítése",
    "position_question": "Milyen szó van az n-ik helyen",
    "mnemonic_help": "Írd le a mnemonikokat a megjelenítés sorrendjében, másold le a szavakat vagy készíts egy fényképet. A következő lépésben ennek a kifejezésnek az egyes szavait kell megerősíteni, majd titkosított formában elmentjük a kifejezést a böngésző tárába. A mnemonik tárolása és a cím használata biztonságosan történik. Vigyázz rá, hogy ne veszítsd el a mnemonikus kifejezést.",
    "start_challenge": "A kihívás kezdete",
    "create_new": "Új készítése",
    "hide_mnemonic": "A mnemonik elrejtése",
    "drop_phrase": "A kifejezés elvetése",
    "scan": "QR-kód pásztázás",
    "create_vanity": "Kérkedő számlanév",
    "vanity_start": "A cím ezzel kezdődik:",
    "vanity_mid": "A cím tartalmazza:",
    "vanity_end": "A cím ezzel végződik:",
    "vanity_count": "Feldolgozott címek száma",
    "vanity_button_start": "Cím keresése",
    "vanity_button_stop": "Keresés leállítása",
    "vanity_workers": "Használni kívánt szálak száma",
    "vanity_use": "Ennek a számlának a használata",
    "auto_lock_off": "Az automatikus zárolás funkció kikapcsolva"
  },
  "import": {
    "title": "Pénztárca importja",
    "wallet_name": "Pénztárca neve",
    "wallet_file": "A pénztárcához tartozó állomány",
    "import_wallet_button": "Pénztárca importálása",
    "help": "Az előzetesen kiexportált pénztárcák beimportálhatók. A pénztárca jelszó az exportált állományon belül van, ezért a pénztárca az importálás után ugyanazzal a jelszóval használható. A bejelentkezés után a jelszó a 'Beállítások' lapon változtatható meg."
  },
  "acc_type": {
    "basic_account": "Egyszerű számla",
    "multisig_account": "Többszörösen aláírt számla",
    "public_account": "Nyilvános számla"
  },
  "pay": {
    "title": "Kifizetés indítása innen: ",
    "toggle_camera": "Fényképezőgép be/kikapcsolása",
    "note_is_b64": "A Megjegyzés base64 kódolású. Ha engedélyezed, akkor base64 formátumban adhatod meg az inputot a megjegyzés mezőben, és ilyen formátumban küldheted el a hálózatnak.",
    "selected_account": "Kiválasztott számla",
    "multisig_account": "Többszörösen aláírt számla",
    "create_proposal": "Ajánlat készítése",
    "sign_proposal": "Ajánlat aláírása & elküldése",
    "signature_from_friend": "Kérjük, itt add be a barátodtól származó aláírást",
    "load_multisig_data": "Többszörösen aláírt adat betöltése",
    "pay_to_wallet": "Fizetés a pénztárcában lévő számlának",
    "pay_to_other": "Fizetés egy másik számlának",
    "store_other_help": "Jobb, ha azt a számlát, aminek a kifizetés történik, a címtárban tárolod",
    "amount": "Összeg",
    "fee": "Díj",
    "note": "Megjegyzés",
    "review_payment": "A kifizetés áttekintése",
    "review_payment_help": "Kérjük, tekintsd át a kifizetést",
    "from_account": "Erről a számláról",
    "pay_to": "Fizetés erre a számlára",
    "total": "Összesen",
    "transaction_details": "A tranzakció részletei",
    "type": "Típus",
    "name": "Név",
    "first_round": "FirstRound",
    "last_round": "LastRound",
    "genesis": "GenesisID",
    "tag": "Tag",
    "to_account": "Kinek",
    "signatures": "Aláírások",
    "signed": "Aláírva",
    "not_signed": "Nincs aláírva",
    "sign_with": "Aláírás ezzel",
    "sign": "Aláírás",
    "send_to_other_signators": "Ennek az adatnak az elküldése a többi aláírónak",
    "send_to_network": "Elküldés a hálózatnak",
    "state_sending": "Az utalás elküldése a hálózatnak",
    "state_sent": "Az utalás elküldve a hálózatnak. Tx: ",
    "state_waiting_confirm": "Várakozás a hálózat megerősítésére.",
    "state_confirmed": "A megerősítés megérkezett. Az utalás bekerült a blokkba",
    "transaction": "Tranzakció",
    "error": "Hiba",
    "asset": "Eszköz",
    "state_error_not_sent": "Az utalás valószínűleg nem jutott el a hálózatra. Offline módba kerültél? Kérjük, ellenőrizd a számládat.",
    "set_max": "Maximum beállítása"
  },
  "settings": {
    "title": "Beállítások",
    "server": "Szerver",
    "environment": "környezet",
    "mainnet": "Mainnet",
    "testnet": "Testnet",
    "devnet": "Devnet elérése",
    "sandbox": "Sandbox",
    "host": "gazdagép",
    "token": "token",
    "language": "Nyelv",
    "pass": "Pénztárca jelszó",
    "oldpass": "Régi jelszó",
    "newpass": "Új jelszó",
    "repeatpass": "Új jelszó - megismételve",
    "backup": "Pénztárca mentés",
    "backup_help": "A pénztárca elmenthető és egy másik számítógépen beimportálható.",
    "create_backup": "Mentés készítése",
    "download": "Letöltés",
    "delete": "Pénztárca törlés",
    "strength": "Erősség",
    "updated_password": "A jelszó módosult",
    "custom": "Egyedi szerver beállítások",
    "protocol_title":"Protokoll kezelő regisztrálása",
    "protocol_button":"web+algorand:// kezelő regisztrálása",
    "protocol_change_success":"A protokoll kezelő módosítása sikeres volt"
  },
  "transaction": {
    "title": "Tranzakció részletei",
    "tr_id": "Tranzakció ID",
    "tr_type": "Tranzakció típus",
    "note": "Megjegyzés",
    "note_raw": "Formázatlan adat",
    "note_decoded": "Dekódolt adat",
    "tr_close_rewards": "Jutalmak lezárása",
    "closing_amount": "Záráskori összeg",
    "confirmed_round": "Megerősített menet",
    "fee": "Díj",
    "first_valid": "Első érvényes",
    "genesis_id": "Genezis id",
    "genesis_hash": "Genezis hash",
    "intra_round": "Belső eltolás",
    "last_valid": "Utolsó érvényes",
    "payment_tr": "Fizetési tranzakció",
    "amount": "Összeg",
    "close_amount": "Záráskori összeg",
    "receiver": "Címzett",
    "receiver_rewards": "Címzett jutalma",
    "round_time": "Egy menet ideje",
    "sender": "Küldő",
    "sender_rewards": "Küldő jutalma",
    "signature": "Aláírás",
    "created_asset": "A létrejött eszköz",
    "asset_name": "Eszköz neve",
    "asset_creator": "Eszköz létrehozó",
    "asset_manager": "Eszköz kezelő",
    "asset_reserve": "Eszköz kibocsájtó",
    "asset_freeze": "Eszköz befagyasztó",
    "asset_clawback": "Eszköz visszakövetelő",
    "asset_unitName": "Eszköz egységének a neve",
    "asset_total": "Összes eszköz",
    "asset_decimals": "Tizedesjegyek száma",
    "asset_defaultfrozen": "Kezdetben legyen befagyasztva",
    "asset_url": "Eszköz url",
    "asset_metadata": "Eszköz metaadat",
    "time": "Idő",
    "group":"Group ID"
  },
  "privacy": {
    "title": "Adatvédelmi irányelvek",
    "q1": "Milyen információkat gyűjtenek rólam?",
    "a1": "Semmilyeneket, az IP címeden és böngésző típusodon kívül, amit minden internet szolgáltató tárol. Nem gyűjtünk semmilyen személyes adatot sem, pl. neveket, e-mail címeket vagy Algorand címeket.",
    "q2": "Mi van sütikben tárolva?",
    "a2": "Semmi. Nem használunk sütiket. Egy indexdb nevű perzisztens lokális tárat használunk a pénztárca tárolására.",
    "q3": "Hogyan történik az analitikai adatok gyűjtése?",
    "a3": "Nem gyűjtünk ilyen adatokat. Semmilyen analitikai szolgáltatást sem használunk, pl. a Google analitikát sem, hogy a felhasználók nagyobb biztonságban érezhessék magukat. Az alkalmazás tisztán html és javascript, és csak akkor tudjuk meg az IP címedet, ha egy kérést küldesz a szervernek. A beállításaidtól és az algod szerveredtől ill. indexelő szolgáltatásodtól függően a kiválasztott szerver szintén tudomást szerezhet az IP címedről.",
    "q4": "Érinti-e ennek a szolgáltatásnak a használata, hogy milyen reklámokat látok?",
    "a4": "Nem. A reklám szolgáltatók semmilyen adatot sem kapnak tőlünk a használatra vonatkozóan. Például az egyetlen mód, amivel a Google tudomást szerez róla, hogy használod ezt a szolgáltatást, ha a webhelyet a Google keresésből éred el.",
    "q5": "Ki nyújtja a szolgáltatást?",
    "a5": "A Scholtz & Company, Kkt. hozta létre ezt a szolgáltatást. Ha a pénztárca a Scholtz & Co által biztosított hivatalos web helyen fut, akkor a cég egyúttal a szolgáltatás nyújtó. A cég nem felel semmilyen szolgáltatásért, sem az alkalmazás esetleges hibáiért."
  },
  "assetcreate": {
    "title": "Új NTF / ASA",
    "show_advanced": "Haladóknak szóló űrlap megjelenítése",
    "assetName": "Barátságos név",
    "creator": "A létrehozó címe",
    "hide_advanced": "Haladóknak szóló űrlap elrejtése",
    "creator_not_found": "Cím létrehozása vagy először cím beimportálása",
    "default_fronzen": "Befagyasztva: a felhasználói számlákat fel kell oldani a tranzakciók végzése előtt",
    "decimals": "Tizedesjegyek: a tizedes jegyek számát megadó egész szám, mely az eszköz egységeinek a számítására használatos",
    "totalIssuance": "Ennek az eszköznek a forgalomban lévő összes darabszáma",
    "unitName": "Arra használjuk, hogy az eszköz egységeit megjelenítsük a felhasználó számára",
    "assetURL": "Egy opcionális string, amely az eszközhöz tartozó URL-re mutat",
    "assetMetadataHash": "Egy opcionális hash, amely valamilyen módon az eszközhöz kapcsolódik. 32 karakter hosszú.",
    "manager": "A kezelő módosítani tudja az eszköz kezelőjének, kibocsátójának, befagyasztójának és visszakövetelőjének a számlacímét",
    "reserve": "Kibocsátó cím: A megadott címet tekinti az eszköz kibocsátójának (nincs semmi különös privilégiuma, csak informális)",
    "freeze": "Befagyasztási cím: A megadott cím képes a felhasználó pénzeszközének befagyasztására vagy a befagyasztás feloldására",
    "clawback": "Visszakövetelési cím: A megadott cím képes a felhasználó pénzeszközét visszavenni és elküldeni egy másik címre",
    "note": "A tranzakció létrehozásakor megadott megjegyzés",
    "create_button": "NFT / ASA létrehozás"
  },

  "faq": {
    "title": "Gyakran ismételt kérdések",
    "q1": "A pénztárcáról",
    "a1": "Az Algo pénztárca alkalmazást eredetileg a Scholtz & Company készítette. Nyílt forráskódú, tisztán html-ben íródott, fő előnye a könnyű használhatóság és a biztonság.",
    "q2": "Mennyire biztonságosan vannak a pénztárca adatai tárolva?",
    "a2": "Az adatok csak a böngészőn belül vannak meg. Az adatok egy perzisztens index db-ben vannak tárolva. A pénztárcák titkosítása aes256-tal, jelszó segítségével történik. Ha a jelszó elég erős, akkor a pénztárca nyers erővel soha sem lesz feltörhető. A jelszó nem hagyja el a számítógépedet, és csak a böngésző gyorsítótárában van meg. A bejelentkezés után 5 perccel a böngésző gyorsítótára törlődik.",
    "q3": "Kikerül-e a pénztárcám jelszava az Internetre?",
    "a3": "Nem, soha",
    "q4": "Hogyan tudom megváltoztatni a pénztárca jelszavát?",
    "a4_1": "Jelentkezz be a pénztárcádba",
    "a4_2": "Menj a 'Beállítások'-hoz",
    "a4_3": "Gépeld be a régi jelszót, az új jelszót, majd ismételten az új jelszót.",
    "a4_4": "Az űrlap elküldése után a jelszó megváltozik.",
    "q5": "Át tudom vinni a pénztárcámat egy másik számítógépre?",
    "a5_1": "Jelentkezz be a pénztárcádba",
    "a5_2": "Menj a 'Beállítások'-hoz",
    "a5_3": "Menj a 'Biztonsági mentés' (backup) részhez és mentsd az adataidat egy helyi állományba.",
    "a5_4": "A másik számítógépen menj az 'Új pénztárca', 'Pénztárca importja' menüponthoz, és töltsd föl az állományt a másik böngésző tárterületére.",
    "a5_5": "A pénztárca ugyanazzal a jelszóval lesz elérhető, mint azelőtt. A jelszó a 'Beállítások'-ban változtatható meg.",
    "q6": "Tudom ellenőrizni a forráskódot?",
    "a6": "Igen. https://github.com/scholtz/wallet/ A pénztárca nyílt forráskódú és szabadon használható, másolható vagy módosítható, még kereskedelmi célokra is. Ha részt szeretnél venni a projektben, akkor hozz létre egy 'pull request'-et, amely a javításaidat tartalmazza.",
    "q7": "Hogyan tudom segíteni a projektet?",
    "a7_1": "A P65LXHA5MEDMOJ2ZAITLZWYSU6W25BF2FCXJ5KQRDUB2NT2T7DPAAFYT3U Algorand címre küldött adományokkal lehet a projektnek segítséget nyújtani. A projektet sem az Algorand Inc., sem az Algorand Foundation nem támogatta.",
    "a7_2": "A projekt más nyelvre történő lefordításához menj a https://github.com/scholtz/wallet/tree/master/src/locales web helyre, fordítsd le az állományt a saját anyanyelvedre, és végezz egy 'pull request'-et.",
    "a7_3": "Ha programozó vagy, végezz kódvizsgálatot, vagy jelezd, hogy miképpen lehetne a projektet jobbá tenni.",
    "q8": "Hogyan készíthetők többszörösen aláírt számlák?",
    "a8_1": "Először hozd létre a privát kulcsokkal rendelkező számlákat vagy hozz létre nyilvános számlákat. Jobb, ha elnevezed a számlákat, hogy később emlékezz rá, milyen célból hoztad őket létre.",
    "a8_2": "Menj az 'Új cím létrehozásá'-hoz, és válaszd ki a többszörösen aláírt számlát.",
    "a8_3": "Válaszd ki az összes számlát, amely aláírhatja a tranzakciókat, és határozd meg, hogy minimum hány aláírásra legyen szükség ahhoz, hogy a hálózat elfogadja a többszörösen aláírt tranzakciót.",
    "a8_4": "A többszörösen aláírt számla a létrehozása után a 'Számlák listázása' lapról érhető el."
  }
}
